import axios from "axios";
import { BASE_URL } from "src/constants";

interface ReturnInterface {
  error: boolean;
  result: any;
}

export const sendRequestJSON = async (
  obj = null,
  service,
  method: string,
  responseType:string = 'json'
): Promise<ReturnInterface> => {
  let config: any;
  if (obj) {
    const data = JSON.stringify(obj);
    const persistData = JSON.parse(localStorage.getItem("persist:root"));
    let loginData: any = {};
    if (typeof persistData?.loginInformation === "string") {
      loginData = JSON.parse(persistData?.loginInformation);
    } else {
      loginData = persistData?.loginInformation;
    }
    const accessToken = loginData?.user?.accessToken;
    const refreshToken = loginData?.user?.refreshToken;
    config = {
      method,
      url: `${BASE_URL}${service}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
        refreshtoken: refreshToken,
      },
      responseType:responseType,
      timeout: 2 * 60 * 1000,
      data,
    };
  } else {
    const persistData = JSON.parse(localStorage.getItem("persist:root"));
    let loginData: any = {};
    if (typeof persistData?.loginInformation === "string") {
      loginData = JSON.parse(persistData?.loginInformation);
    } else {
      loginData = persistData?.loginInformation;
    }
    const accessToken = loginData?.user?.accessToken;
    const refreshToken = loginData?.user?.refreshToken;
    config = {
      method,
      url: `${BASE_URL}/${service}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
        refreshtoken: refreshToken,
      },
      timeout: 2 * 60 * 1000,
      responseType:responseType
    };
  }

  const returnOBJ: any = { error: false, result: {} };

  await axios(config)
    .then(async (response) => {
      const result: any = response;
      returnOBJ.result = result;
    })
    .catch(async (err) => {
      if(err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
      returnOBJ.error = true;
      returnOBJ.result = err.response.data;
    });

  try {
    if (returnOBJ.error === true) {
      return returnOBJ;
    } else if (returnOBJ.error === false) {
      return returnOBJ;
    }
  } catch (e) {
    return null;
  }
};

export const sendRequestFiles = async (
  files,
  service
): Promise<ReturnInterface> => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("attachmentFile", files[i]);
  }
  const returnOBJ: any = { error: false, result: {} };
  try {
    const response = await axios.post(`${BASE_URL}${service}`, formData, {
      timeout: 1800000,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(response);
    returnOBJ.result = response;
    returnOBJ.error = false;
    return returnOBJ;
  } catch (error) {
    console.error(error);
    returnOBJ.result = error;
    returnOBJ.error = true;
    return returnOBJ;
  }
};
