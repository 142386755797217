export const columns = ({updateStatus}) => [
  {
    accessorKey: "customerName",
    header: "Customer Name",
    size: 40,
  },
  {
    accessorKey: "projectName",
    header: "Project Name",
    size: 40,
  },
  {
    accessorKey: "managerName",
    header: "Manager Name",
    size: 40,
  },
  {
    accessorKey: "projectStatusName",
    header: "Status",
    size: 40,
    // Cell : ({renderedCellValue, row}) => (
    //   <Switch
    //     checked = {renderedCellValue}
    //     onChange={() => updateStatus(!renderedCellValue, row.original.projectId)}
    //   />
    // )
  },
  {
    accessorKey: "startDate",
    header: "Start Date",
    size: 40,
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    size: 40,
  },
 
];
