import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { IEmployeeSlice } from "./types";

const selectSelf = (state: RootState) => state;
const selectEmployee = createSelector(
  selectSelf,
  (state) => state.employee
);

export const selectIsEmployeeLoading = createSelector(
  selectEmployee,
  (employee: IEmployeeSlice) => employee.isLoading
);

export const selectEmployeeList = createSelector(
  selectEmployee,
  (employee: IEmployeeSlice) => employee.employeeList
);

export const selectCountryList = createSelector(
  selectEmployee,
  (employee: IEmployeeSlice) => employee.countryList
);
