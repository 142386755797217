import { createSlice } from "@reduxjs/toolkit";
import { IEmployeeSlice } from "./types";
import // fetchAllProjects,
// fetchAllTasks,
"./thunks";
import { createEmployee, fetchCountryList, fetchEmployeeList } from "./thunks";

const initialState: IEmployeeSlice = {
  employeeList: [],
  countryList: [],
  isLoading: false,
  isError: false,
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState: initialState,
  reducers: {
    addEmployee(state, action): void {},
  },
  extraReducers: (builder) => {
    // fetchCountryList
    builder.addCase(fetchCountryList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCountryList.fulfilled, (state, action) => {
      state.countryList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCountryList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchEmployeeList
    builder.addCase(fetchEmployeeList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEmployeeList.fulfilled, (state, action) => {
      state.employeeList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeeList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // createEmployee
    builder.addCase(createEmployee.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createEmployee.fulfilled, (state, action) => {
      // state.employeeList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createEmployee.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { addEmployee } = employeeSlice.actions;
export const { reducer } = employeeSlice;
