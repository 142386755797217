import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
import { ContainedButton, OutlinedButton } from "src/components/button-group";
import {
  createEmployee,
  editEmployee,
  fetchCountryList,
} from "src/slices/employee/thunks";
import { AppDispatch } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { selectCountryList } from "src/slices/employee/selectors";
import { IEmployee } from "src/slices/employee/types";

interface IFormikValues {
  employeeCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  email: string;
  religion: string;
  birthDate: Date;
  hireDate: Date;
  CNIC: string;
  CNICExpiryDate: Date;
  roleId: string;
  isMarried: boolean;
  contact: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: number;
}

export function CreateEmployee() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { state } = useLocation();

  const formikRef = useRef(null);

  const countryList = useSelector(selectCountryList);

  // Form Inital Values
  const formInitialValue: IFormikValues = {
    employeeCode: "",
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: new Date(Date.now()),
    hireDate: new Date(Date.now()),
    CNIC: "",
    CNICExpiryDate: new Date(Date.now()),
    gender: "M",
    roleId: "",
    religion: "",
    isMarried: false,
    contact: "",
    email: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: 166,
  };

  // Form Fields Validation
  const validationSchemaModel = Yup.object().shape({
    contact: Yup.string().required("You must insert a contact number").matches(
      /^[0-9-]*$/,
      "Contact should be valid"
    ),
    postalCode: Yup.number().required("POstal Code must be a number"),
    firstName: Yup.string().matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      "First Name should be alphabetic"
    ),
    lastName: Yup.string().matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      "Last Name should be alphabetic"
    ),
    city: Yup.string().matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      "City should be alphabetic"
    ),
    state: Yup.string().matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      "State should be alphabetic"
    ),
    CNIC: Yup.string().matches(
      /^[0-9-]*$/,
      "CNIC is not valid"
    ),
    email:Yup.string().email('Email should be valid'),
  });

  // Click Events
  const onClickSubmit = async (values) => {
    if (state?.employee) {
      dispatch(
        editEmployee({
          ...values,
          employeeId: state.employee.employeeId,
          isActive: state.employee.isActive,
        })
      ).then(() => {
        navigate(-1);
      });
      return;
    }

    dispatch(
      createEmployee({
        ...values,
        employeeCode: "ST-" + values.employeeCode,
        isActive: true
      })
    ).then(() => {
      navigate(-1);
    });;
  };

  useEffect(() => {
    if (countryList.length === 0) {
      dispatch(fetchCountryList());
    }
  }, [countryList, dispatch]);

  useEffect(() => {
    if (state?.employee) {
      const employee: IEmployee = state.employee;
      formikRef.current.setFieldValue("employeeCode", employee.employeeCode);
      formikRef.current.setFieldValue("firstName", employee.firstName);
      formikRef.current.setFieldValue("middleName", employee.middleName);
      formikRef.current.setFieldValue("lastName", employee.lastName);
      formikRef.current.setFieldValue("gender", employee.gender);
      formikRef.current.setFieldValue("email", employee.email);
      formikRef.current.setFieldValue("CNIC", employee.CNIC);
      formikRef.current.setFieldValue("CNICExpiryDate", new Date(employee.CNICExpiryDate));
      formikRef.current.setFieldValue("birthDate", new Date(employee.birthDate));
      formikRef.current.setFieldValue("hireDate", new Date(employee.hireDate));
      formikRef.current.setFieldValue("email", employee.email);
      formikRef.current.setFieldValue("roleId", employee.roleId);
      formikRef.current.setFieldValue("religion", employee.religion);
      formikRef.current.setFieldValue("contact", employee.contact);
      formikRef.current.setFieldValue("address", employee.address);
      formikRef.current.setFieldValue("city", employee.city);
      formikRef.current.setFieldValue("state", employee.state);
      formikRef.current.setFieldValue("postalCode", employee.postalCode);
      formikRef.current.setFieldValue("country", employee.countryId);
      formikRef.current.setFieldValue("isMarried", employee.isMarried);
    }
  }, [state]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Formik
          initialValues={formInitialValue}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={(values) => {
            onClickSubmit(values);
          }}
        >
          {({ values, touched, errors, setFieldValue, getFieldProps }) => (
            <Form id="CreateEmployee">
              {/* Personal Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Personal Information
                  </CTypography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikControl
                        control="InputField"
                        required
                        disabled={state?.employee}
                        name="employeeCode"
                        label={"Employee Code"}
                        error={Boolean(
                          touched.employeeCode && errors.employeeCode
                        )}
                        helperText={touched.employeeCode && errors.employeeCode}
                        {...getFieldProps("employeeCode")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="firstName"
                    label={"First Name"}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    {...getFieldProps("firstName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="middleName"
                    label={"Middle Name"}
                    error={Boolean(touched.middleName && errors.middleName)}
                    helperText={touched.middleName && errors.middleName}
                    {...getFieldProps("middleName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="lastName"
                    label={"Last Name"}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    {...getFieldProps("lastName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="gender"
                    label="Gender"
                    options={[
                      {
                        label: "Male",
                        value: "M",
                      },
                      {
                        label: "Female",
                        value: "F",
                      },
                    ]}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                    {...getFieldProps("gender")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="CNIC"
                    label="CNIC"
                    error={Boolean(touched.CNIC && errors.CNIC)}
                    helperText={touched.CNIC && errors.CNIC}
                    {...getFieldProps("CNIC")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="CalendarTime"
                    label="CNIC Expiry Date"
                    dateOrTimeOnly="date"
                    name="CNICExpiryDate"
                    error={Boolean(touched.CNICExpiryDate && errors.CNICExpiryDate)}
                    helperText={touched.CNICExpiryDate && errors.CNICExpiryDate}
                    {...getFieldProps("CNICExpiryDate")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="email"
                    label="Email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    {...getFieldProps("email")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="religion"
                    label="Religion"
                    error={Boolean(touched.religion && errors.religion)}
                    helperText={touched.religion && errors.religion}
                    {...getFieldProps("religion")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="CalendarTime"
                    label="Birth Date"
                    dateOrTimeOnly="date"
                    name="birthDate"
                    error={Boolean(touched.birthDate && errors.birthDate)}
                    helperText={touched.birthDate && errors.birthDate}
                    {...getFieldProps("birthDate")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="CalendarTime"
                    label="Hire Date"
                    dateOrTimeOnly="date"
                    name="hireDate"
                    error={Boolean(touched.hireDate && errors.hireDate)}
                    helperText={touched.hireDate && errors.hireDate}
                    {...getFieldProps("hireDate")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="roleId"
                    label="Role"
                    options={[
                      {
                        label: "Admin",
                        value: 1,
                      },
                      {
                        label: "Employee",
                        value: 2,
                      },
                    ]}
                    error={Boolean(touched.roleId && errors.roleId)}
                    helperText={touched.roleId && errors.roleId}
                    {...getFieldProps("roleId")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                      <Checkbox 
                        checked={values.isMarried}
                        onChange={(e)=>setFieldValue('isMarried',e.target.checked)}
                      />}
                      label="Is Married"
                    />
                  </FormGroup>
                  {/* <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Gender
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="isMarried"
                      value={'female'}
                      onChange={()=>{}}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                    </RadioGroup>
                  </FormControl> */}
                </Grid>
              </Grid>

              {/* Contact Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Contact Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="contact"
                    label="Contact"
                    error={Boolean(touched.contact && errors.contact)}
                    helperText={touched.contact && errors.contact}
                    {...getFieldProps("contact")}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormikControl
                    required
                    control="InputField"
                    name="address"
                    label="Address"
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    {...getFieldProps("address")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="InputField"
                    name="city"
                    label="City"
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                    {...getFieldProps("city")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="InputField"
                    name="state"
                    label="State"
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                    {...getFieldProps("state")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="InputField"
                    name="postalCode"
                    label="Postal Code"
                    error={Boolean(touched.postalCode && errors.postalCode)}
                    helperText={touched.postalCode && errors.postalCode}
                    {...getFieldProps("postalCode")}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="InputField"
                    name="country"
                    label="Country"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    {...getFieldProps("country")}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="SelectField"
                    name="country"
                    label="Country"
                    options={countryList.map((country) => ({
                      label: country.name,
                      value: country.id,
                    }))}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    {...getFieldProps("country")}
                  />
                </Grid>
              </Grid>

              {/* SUBMIT FORM */}
              <Grid container spacing={1}>
                <Grid item>
                  <ContainedButton
                    // onClick={() => {
                    //   onClickSubmit(values);
                    // }}
                    type="submit"
                  >
                    SUBMIT
                  </ContainedButton>
                </Grid>
                <Grid item>
                  <OutlinedButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    CANCEL
                  </OutlinedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
