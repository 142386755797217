import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { EmployeeAPIService } from "src/constants";
import { logout } from "../auth/thunks";
import { IAddEmployeePayload } from "./types";

export const fetchCountryList = createAsyncThunk(
  "employee/fetchCountryList",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        EmployeeAPIService.GET_COUNTRY_LIST,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const fetchEmployeeList = createAsyncThunk(
  "employee/fetchEmployeeList",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        EmployeeAPIService.GET_EMPLOYEE_DATA,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const createEmployee = createAsyncThunk(
  "employee/createEmployee",
  async (payload: IAddEmployeePayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        EmployeeAPIService.ADD_EMPLOYEE,
        "post"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const editEmployee = createAsyncThunk(
  "employee/editEmployee",
  async (payload: IAddEmployeePayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        EmployeeAPIService.EDIT_EMPLOYEE,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (payload: any, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        EmployeeAPIService.ENABLE_DISABLE_EMPLOYEE,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);
