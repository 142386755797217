import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { CustomerAPIService } from "src/constants";
import { logout } from "../auth/thunks";
import { IAddCustomerPayload } from "./types";

export const fetchCountryList = createAsyncThunk(
  "customer/fetchCountryList",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        CustomerAPIService.GET_COUNTRY_LIST,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const fetchCustomerList = createAsyncThunk(
  "customer/fetchCustomerList",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        CustomerAPIService.GET_CUSTOMER_DATA,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (payload: IAddCustomerPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        CustomerAPIService.ADD_CUSTOMER,
        "post"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const editCustomer = createAsyncThunk(
  "customer/editCustomer",
  async (payload: IAddCustomerPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        CustomerAPIService.EDIT_CUSTOMER,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (payload: any, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        CustomerAPIService.ENABLE_DISABLE_CUSTOMER,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);
