// URLs
const BASE_URL = process.env.REACT_APP_API_SERVICE_URL;

const AuthAPIService = {
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  VERIFICATION: "/auth/verify-otp",
  REFRESH: "/auth/refresh-token",
};

const TimesheetAPIService = {
  GET_TIMESHEET_DATA: "/timesheet/get-timesheet-data",
  GET_PM_TIMESHEET: "/timesheet/get-pm-timesheet",
  GET_ALL_EMP_TIMESHEET:"/timesheet/get-all-emp-timesheet",
  GET_PROJECTS: "/timesheet/get-projects",
  GET_PM_PROJECTS: "/timesheet/get-pm-projects",
  GET_TASKS: "/timesheet/get-tasks",
  SAVE_TIMESHEET: "/timesheet/save-timesheet",
  EDIT_TIMESHEET: "/timesheet/edit-timesheet",
  SUBMIT_TIMESHEET: "/timesheet/submit-timesheet",
  UNSUBMIT_TIMESHEET: "/timesheet/unsubmit-timesheet",
  APPROVE_REJECT_TIMESHEET: "/timesheet/approve-reject-timesheet",
  EXPORT_REPORT: "/timesheet/export-report"
};

const CustomerAPIService = {
  GET_CUSTOMER_DATA: "/admin-settings/customer-listing",
  ADD_CUSTOMER: "/admin-settings/add-customer",
  EDIT_CUSTOMER: "/admin-settings/edit-customer",
  ENABLE_DISABLE_CUSTOMER: "/admin-settings/enable-disable-customer",
  GET_COUNTRY_LIST: "/country/get-country-list",
};

const EmployeeAPIService = {
  GET_EMPLOYEE_DATA: "/admin-settings/employee-listing",
  ADD_EMPLOYEE: "/admin-settings/add-employee",
  EDIT_EMPLOYEE: "/admin-settings/edit-employee",
  ENABLE_DISABLE_EMPLOYEE: "/admin-settings/enable-disable-employee",
  GET_COUNTRY_LIST: "/country/get-country-list",
};

const ProjectAPIService = {
  GET_PROJECT_DATA: "/admin-settings/project-listing",
  ENABLE_DISABLE_PROJECT: "/admin-settings/enable-disable-project",
  ADD_PROJECT: "/admin-settings/add-project",
  EDIT_PROJECT: "/admin-settings/edit-project",
  GET_MANAGER_LIST: "/admin-settings/manager-listing",
  GET_PRIORITY_LIST: "/admin-settings/priority-list",
  GET_PROJECT_STATUS_LIST: "/admin-settings/project-status-list",
}

const EmpProjectAPIService = {
  GET_EMP_PROJECT_LIST: "/admin-settings/employee-project-listing",
  ADD_EMP_PROJECT: "/admin-settings/add-employee-project",
  EDIT_EMP_PROJECT: "/admin-settings/edit-employee-project"
}

// REGEXs
const REGEX_IS_NUMBER = /^\d+$/;

// Routes
const RoutePaths = {
  LOGIN: "/login",
  VERIFICATION: "/verification",
  TIMESHEET_LISTING: "/",
  APPROVAL_TIMESHEET: "/approval-timesheet",
  WEELY_TIMESHEET: "/weekly-timesheet",

  CREATE_EDIT_CUSTOMER: "/create-edit-customer",
  CUSTOMER_LISTING: "/customer-listing",

  EMPLOYEE_LISTING: "/employee-listing",
  CREATE_EDIT_EMPLOYEE: "/create-edit-employee",

  REPORTS:"/reports",
  
  PROJECTS:"/projects",
  CREATE_EDIT_PROJECT: "/create-edit-project",

  EMP_PROJECT_LISTING:"/employee-project-listing"
};

// Slices
const Slices = {
  LOGIN_INFORMATION: "loginInformation",
  COMMON_REDUCER: "commonReducer",
  UI_SETTINGS: "uiSettings",
};

export {
  BASE_URL,
  AuthAPIService,
  TimesheetAPIService,
  CustomerAPIService,
  ProjectAPIService,
  EmpProjectAPIService,
  EmployeeAPIService,
  REGEX_IS_NUMBER,
  RoutePaths,
  Slices,
};
