import { Button, FormLabel, Grid, TextField } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useState } from "react";
import { columns } from "./config/table-columns";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import {
  exportReport,
  fetchAllEmpTimesheet,
} from "src/slices/timesheet/thunks";
import { IFetchAllEmpTimesheet } from "src/slices/timesheet/types";
import { toastMessage } from "src/slices/commonSlice";
import { selectAllEmpTimesheet } from "src/slices/timesheet/selectors";

export const Reports = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const allTimesheets: any[] = useSelector(selectAllEmpTimesheet);

  const getTimesheet = () => {
    if (!startDate || !endDate) {
      dispatch(
        toastMessage({
          error: true,
          message: "Start and End Date Required",
        })
      );

      return;
    }
    const queryObj: IFetchAllEmpTimesheet = {
      startDate,
      endDate,
    };
    dispatch(fetchAllEmpTimesheet(queryObj));
  };

  const exportTimesheet = () => {
    if (!startDate || !endDate) {
      dispatch(
        toastMessage({
          error: true,
          message: "Start and End Date Required",
        })
      );
      return;
    }
    dispatch(
      exportReport({
        startDate: startDate,
        endDate: endDate,
      })
    );
  };

  return (
    <Grid container spacing={3} minHeight="100vh" alignContent="start">
      <Grid item xs={12}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <FormLabel>Start Date</FormLabel>
            <TextField
              fullWidth
              type="date"
              variant="outlined"
              // label="Start Date"
              value={startDate}
              name="startDate"
              onChange={(e: any) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormLabel>End Date</FormLabel>
            <TextField
              fullWidth
              type="date"
              variant="outlined"
              // label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3} display={"flex"} alignItems={"end"}>
            <Button
              fullWidth
              variant="contained"
              sx={{ paddingY: "11.5px", fontSize: "14px" }}
              onClick={getTimesheet}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} md={3} display={"flex"} alignItems={"end"}>
            <Button
              fullWidth
              variant="contained"
              sx={{ paddingY: "11.5px", fontSize: "14px" }}
              onClick={exportTimesheet}
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ fontSize: "15px", fontWeight: "700" }}>
        <MaterialReactTable
          muiTableBodyRowProps={{ hover: false }}
          muiTableProps={{
            sx: {
              tableLayout: "auto",
            },
          }}
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              "& td:nth-of-type(odd)": {
                backgroundColor: "#ECECEC",
              },
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              fontWeight: "normal",
              fontSize: "11px",
            },
          }}
          columns={columns}
          data={allTimesheets}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
        />
      </Grid>
    </Grid>
  );
};
