import { 
  // useDispatch, 
  useSelector } from "react-redux";
import CModal from "src/components/CModal";
import { 
  // AppDispatch, 
  RootState } from "src/store";
import { DeclineTimesheet } from "../decline-timesheet";

export const DeclineTimesheetModal = ({ declineTimesheetData }) => {
    const { dialog } = useSelector((state: RootState) => state.uiSettings);
    // const dispatch = useDispatch<AppDispatch>();
  
    return dialog.DeclineTimesheet !== undefined &&
      dialog.DeclineTimesheet ? (
      <CModal
        dialogName="DeclineTimesheet"
        title="Decline Timesheet"
        maxWidth="xs"
        showCancelButton={false}
        handleSaveButtonText="Submit"
        open={
          dialog.DeclineTimesheet === undefined
            ? false
            : dialog.DeclineTimesheet
        }
        content={<DeclineTimesheet dialogName="DeclineTimesheet" declineTimesheetData={declineTimesheetData} />}
      />
    ) : null;
  };