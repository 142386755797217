import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { AuthAPIService } from "src/constants";
import { AuthState } from "src/types/auth/auth";

export const login = createAsyncThunk(
  "auth/login",
  async (email: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          email,
        },
        AuthAPIService.LOGIN,
        "post"
      );
      if (!response.error) {
        console.log('response.result', response.result);
        return {
          isOTP: response.result.data.message.includes("OTP"),
          email: email,
          data: response.result.data.data,
        };
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return null;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (otp: string, { dispatch, getState }) => {
    const state: AuthState = (getState() as any).loginInformation;
    try {
      const response = await sendRequestJSON(
        {
          email: state.userEmail,
          otp: otp,
        },
        AuthAPIService.VERIFICATION,
        "post"
      );
      if (!response.error) {
        return response.result.data.data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return null;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, getState }) => {
    const state: AuthState = (getState() as any).loginInformation;
    try {
      const response = await sendRequestJSON(
        null,
        `${AuthAPIService.LOGOUT}?employeeId=${state.user.employeeInfo.employeeId}`,
        "get"
      );
      if (!response.error) {
        return true;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return false;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const refeshToken = createAsyncThunk(
  "auth/refeshToken",
  async (_, { dispatch, getState }) => {
    const state: AuthState = (getState() as any).loginInformation;
    try {
      const response = await sendRequestJSON(
        null,
        `${AuthAPIService.REFRESH}?employeeId=${state.user.employeeInfo.employeeId}`,
        "get"
      );
      if (!response.error) {
        return response.result.data.data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        if (response.result.message === "jwt expired") {
          dispatch(logout());
          return null;
        }
        return null;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      return null;
    }
  }
);
