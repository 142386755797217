import { createSlice } from "@reduxjs/toolkit";
import { IProjectSlice } from "./types";
import { createProejct, fetchManagerList, fetchPriorityList, fetchProjectListing, fetchProjectStatusList } from "./thunk";

const initialState: IProjectSlice = {
  projectList: [],
  managerList: [],
  priorityList: [],
  projectStatusList: [],
  isLoading: false,
  isError: false,
};

export const projectSlice = createSlice({
  name:'Project',
  initialState,
  reducers:{
  },
  extraReducers: (builder) => {
    //fetch project list
    builder.addCase(fetchProjectListing.pending, (state)=>{
      state.isLoading = false;
    });
    builder.addCase(fetchProjectListing.fulfilled, (state, action) => {
      state.projectList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchProjectListing.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    })
    
    //fetch manager list
    builder.addCase(fetchManagerList.pending, (state)=>{
      state.isLoading = false;
    });
    builder.addCase(fetchManagerList.fulfilled, (state, action) => {
      state.managerList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchManagerList.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    })
    
    //fetch priority list
    builder.addCase(fetchPriorityList.pending, (state)=>{
      state.isLoading = false;
    });
    builder.addCase(fetchPriorityList.fulfilled, (state, action) => {
      state.priorityList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchPriorityList.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    })
    
    //fetch project status list
    builder.addCase(fetchProjectStatusList.pending, (state)=>{
      state.isLoading = false;
    });
    builder.addCase(fetchProjectStatusList.fulfilled, (state, action) => {
      state.projectStatusList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchProjectStatusList.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    })

    //createProject
    builder.addCase(createProejct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProejct.fulfilled, (state, action) => {
      // state.employeeList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createProejct.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // builder.addCase(deleteProject.pending, (state)=>{
    //   state.isLoading = false;
    // });
    // builder.addCase(deleteProject.fulfilled, (state,action)=>{
    //   dispatch
    // })
  }
})

export const { reducer } = projectSlice;