import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormikControl from "src/components/formik/FormikControl";
import { selectEmployeeList } from "src/slices/employee/selectors";
import { fetchEmployeeList } from "src/slices/employee/thunks";
import { IEmployee } from "src/slices/employee/types";
import { AppDispatch } from "src/store";
import {
  empProjectInitialValue,
  validationSchemaModel,
} from "../config/formConfig";
import { IProject } from "src/slices/project/types";
import { IEmpProjectPayload } from "src/slices/employee-project/types";
import { fetchProjectListing } from "src/slices/project/thunk";
import { selectProjectList } from "src/slices/project/selectors";
import { createEmpProejct, fetchEmpProjectList, updateEmpProejct } from "src/slices/employee-project/thunk";

const CreateEditEmpProject = ({ open, handleClose, data }) => {
  const employeeList: IEmployee[] = useSelector(selectEmployeeList);
  const projectList: IProject[] = useSelector(selectProjectList);
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);

  useEffect(() => {
    dispatch(fetchEmployeeList());
    dispatch(fetchProjectListing())
  }, [dispatch]);

  const onClickSubmit = (values: any) => {
    if(data){
      dispatch(updateEmpProejct({
        ...values,
        employeeProjectId: data.employeeProjectId
      })).then(res => {
        dispatch(fetchEmpProjectList())
        handleClose()
      })
    }
    else 
      dispatch(createEmpProejct(values)).then(res => {
        dispatch(fetchEmpProjectList())
        handleClose()
      })
  };

  useEffect(() => {
    if(data){
      const project: IEmpProjectPayload = data;
      formikRef.current.setFieldValue("employeeId", project.employeeId);
      formikRef.current?.setFieldValue('projectId', project.projectId);
      formikRef.current?.setFieldValue('startDate', project.startDate);
      formikRef.current?.setFieldValue('endDate', project.endDate);
      formikRef.current?.setFieldValue('assignmentStatus', project.assignmentStatus);
      formikRef.current?.setFieldValue('allocation', project.allocation);
    }
  }, [data])
  

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth={true}
      disablePortal={true}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#F15C3B",
          color: "white",
          fontWeight: "bold",
          fontSize: "25px",
        }}
      >
        Assign Project
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={empProjectInitialValue}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={(values) => {
            onClickSubmit(values);
          }}
        >
          {({ touched, errors, getFieldProps, values }) => (
            <Form>
              <Box
                sx={{
                  mx: 2,
                  mt: 6,
                  px: 2,
                  py: 4,
                  border: "1px #A6A0A0 solid",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography color={"primary"}>
                      PROJECT ASSIGNMENT
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikControl
                      control="SelectField"
                      required
                      options={employeeList.map((emp) => ({
                        label: emp.firstName + " " + emp.lastName,
                        value: emp.employeeId,
                      }))}
                      name="employeeId"
                      label={"Employee Name"}
                      key={values.employeeId}
                      error={Boolean(touched.employeeId && errors.employeeId)}
                      helperText={touched.employeeId && errors.employeeId}
                      {...getFieldProps("employeeId")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikControl
                      control="CalendarTime"
                      required
                      name="startDate"
                      label={"Start Date"}
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      dateOrTimeOnly="date"
                      {...getFieldProps("startDate")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <FormikControl
                      control="SelectField"
                      required
                      options={projectList.map((proj) => ({
                        label: proj.projectName ,
                        value: proj.projectId,
                      }))}
                      name="projectId"
                      key={values.projectId}
                      label={"Project Name"}
                      error={Boolean(touched.projectId && errors.projectId)}
                      helperText={touched.projectId && errors.projectId}
                      {...getFieldProps("projectId")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <FormikControl
                      control="CalendarTime"
                      required
                      name="endDate"
                      label={"End Date"}
                      error={Boolean(touched.endDate && errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      dateOrTimeOnly="date"
                      {...getFieldProps("endDate")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikControl
                      control="SelectField"
                      required
                      options={[
                        {label:'True', value: true},
                        {label:'False', value: false}
                      ]}
                      name="assignmentStatus"
                      // key={values.assignmentStatus}
                      label={"Assignment Status"}
                      error={Boolean(touched.assignmentStatus && errors.assignmentStatus)}
                      helperText={touched.assignmentStatus && errors.assignmentStatus}
                      {...getFieldProps("assignmentStatus")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikControl
                      control="InputField"
                      required
                      name="allocation"
                      label={"Allocation"}
                      error={Boolean(touched.allocation && errors.allocation)}
                      helperText={touched.allocation && errors.allocation}
                      {...getFieldProps("allocation")}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" onClick={()=>formikRef.current.submitForm()}>
          SUBMIT
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditEmpProject;
