import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ITimesheetSlice } from "./types";

const selectSelf = (state: RootState) => state;
const selectTimesheet = createSelector(
  selectSelf,
  (state) => state.timesheet
);

export const selectIsTimesheetLoading = createSelector(
  selectTimesheet,
  (timesheet: ITimesheetSlice) => timesheet.isLoading
);

export const selectTimesheetList = createSelector(
  selectTimesheet,
  (timesheet: ITimesheetSlice) => timesheet.timesheetList
);

export const selectPmTimesheetList = createSelector(
  selectTimesheet,
  (timesheet) => timesheet.pmTimesheetList
);

export const selectAllProjects = createSelector(
  selectTimesheet,
  (timesheet: ITimesheetSlice) => timesheet.allProjects
);

export const selectAllPMProjects = createSelector(
  selectTimesheet,
  (timesheet: ITimesheetSlice) => timesheet.allPMProjects
);

export const selectAllTasks = createSelector(
  selectTimesheet,
  (timesheet: ITimesheetSlice) => timesheet.allTasks
);

export const selectAllEmpTimesheet = createSelector(
  selectTimesheet,
  (timesheet: ITimesheetSlice) => timesheet.allEmpTimesheet
);
