import { 
  // useDispatch, 
  useSelector } from "react-redux";
import CModal from "src/components/CModal";
// import { setDialog } from "src/slices/uiSettingsSlice";
import { 
  // AppDispatch, 
  RootState } from "src/store";
import { ForgetPassword } from "../forget-password";

export const ForgetPasswordModal = () => {
    const { dialog } = useSelector((state: RootState) => state.uiSettings);
    // const dispatch = useDispatch<AppDispatch>();
  
    return dialog.ForgetPassword !== undefined &&
      dialog.ForgetPassword ? (
      <CModal
        dialogName="ForgetPassword"
        title="Forget Password"
        maxWidth="xs"
        showCancelButton={false}
        handleSaveButtonText="Submit"
        open={
          dialog.ForgetPassword === undefined
            ? false
            : dialog.ForgetPassword
        }
        content={<ForgetPassword dialogName="ForgetPassword" />}
      />
    ) : null;
  };