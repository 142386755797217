import { 
  // useDispatch, 
  useSelector } from "react-redux";
import CModal from "src/components/CModal";
import { 
  // AppDispatch, 
  RootState } from "src/store";
import { ApproveTimesheet } from "../approve-timesheet";

export const ApproveTimesheetModal = ({ approveTimesheetData }) => {
    const { dialog } = useSelector((state: RootState) => state.uiSettings);
    // const dispatch = useDispatch<AppDispatch>();
  
    return dialog.ApproveTimesheet !== undefined &&
      dialog.ApproveTimesheet ? (
      <CModal
        dialogName="ApproveTimesheet"
        title="Approve Timesheet"
        maxWidth="xs"
        showCancelButton={false}
        handleSaveButtonText="Submit"
        open={
          dialog.ApproveTimesheet === undefined
            ? false
            : dialog.ApproveTimesheet
        }
        content={<ApproveTimesheet dialogName="ApproveTimesheet" approveTimesheetData={approveTimesheetData} />}
      />
    ) : null;
  };