import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { logout } from "../auth/thunks";
import { ProjectAPIService } from "src/constants";
import { IProjectPayload } from "./types";

export const fetchProjectListing = createAsyncThunk(
  "project/fetchProjectList",
  async(_ ,{dispatch}) => {
    try {
      const response = await sendRequestJSON(
        null,
        ProjectAPIService.GET_PROJECT_DATA,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
)
export const fetchManagerList = createAsyncThunk(
  "project/managerList",
  async(_ ,{dispatch}) => {
    try {
      const response = await sendRequestJSON(
        null,
        ProjectAPIService.GET_MANAGER_LIST,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
)
export const fetchPriorityList = createAsyncThunk(
  "project/priorityList",
  async(_ ,{dispatch}) => {
    try {
      const response = await sendRequestJSON(
        null,
        ProjectAPIService.GET_PRIORITY_LIST,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
)

export const fetchProjectStatusList = createAsyncThunk(
  "project/projectStatusList",
  async(_ ,{dispatch}) => {
    try {
      const response = await sendRequestJSON(
        null,
        ProjectAPIService.GET_PROJECT_STATUS_LIST,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
)

export const createProejct = createAsyncThunk(
  "employee/createProejct",
  async (payload: IProjectPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        ProjectAPIService.ADD_PROJECT,
        "post"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const editProject = createAsyncThunk(
  "employee/editProjectProejct",
  async (payload: IProjectPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        ProjectAPIService.EDIT_PROJECT,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (payload: any, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        ProjectAPIService.ENABLE_DISABLE_PROJECT,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);