import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthState } from "src/types/auth/auth";
import { login, logout, refeshToken, verifyOtp } from "./thunks";

const initialState: AuthState = {
  userEmail: "",
  isLoggedIn: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setLoginUser(state: AuthState, action: PayloadAction<AuthState>): void {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.user = action.payload.user;
    },
    setLogout(state: AuthState): void {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(login.pending, (_) => {});
    builder.addCase(login.fulfilled, (state, action) => {
      if (!action.payload) return;

      if (action.payload?.isOTP) {
        state.userEmail = action.payload.email;
      } else {
        state.isLoggedIn = true;
        state.userEmail = action.payload.email;
        state.user = action.payload.data;
        localStorage.setItem(
          "persist:root",
          JSON.stringify({ loginInformation: state })
        );
      }
    });
    builder.addCase(login.rejected, (_) => {});

    // verifyOtp
    builder.addCase(verifyOtp.pending, (_) => {});
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      if (action.payload) {
        state.isLoggedIn = true;
        state.user = action.payload;
        localStorage.setItem(
          "persist:root",
          JSON.stringify({ loginInformation: state })
        );
      }
    });
    builder.addCase(verifyOtp.rejected, (_) => {});

    //logout
    builder.addCase(logout.pending, (_) => {});
    builder.addCase(logout.fulfilled, (state, action) => {
      if (action.payload) {
        state.userEmail = "";
        state.isLoggedIn = false;
        state.user = null;
      }
    });
    builder.addCase(logout.rejected, (_) => {});

    // refeshToken
    builder.addCase(refeshToken.pending, (_) => {});
    builder.addCase(refeshToken.fulfilled, (state, action) => {
      if (action.payload) {
        state.isLoggedIn = true;
        state.user.accessToken = action.payload.accessToken;
      }
    });
    builder.addCase(refeshToken.rejected, (_) => {});
  },
});

export const { setLoginUser, setLogout } = authSlice.actions;
export const { reducer } = authSlice;
