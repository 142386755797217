import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectUser } from "src/slices/auth/selectors";
import {
  selectAllPMProjects,
  selectPmTimesheetList,
} from "src/slices/timesheet/selectors";
import {
  fetchAllPmProjects,
  fetchAllProjects,
  fetchPmTimesheetList,
} from "src/slices/timesheet/thunks";
import { IProject } from "src/slices/timesheet/types";
import { AppDispatch } from "src/store";
import { AppUser } from "src/types/auth/auth";
import { DeclineTimesheetModal } from "./decline-timesheet-modal";
import { setDialog } from "src/slices/uiSettingsSlice";
import { ApproveTimesheetModal } from "./approve-timesheet-modal";
import { dayMonthYearFormat } from "src/lib/utils";

export const ApprovalTimesheet = () => {
  const user: AppUser = useSelector(selectUser);

  const dispatch = useDispatch<AppDispatch>();

  const allProjects: IProject[] = useSelector(selectAllPMProjects);
  const timesheetListing = useSelector(selectPmTimesheetList);

  const [selectedProject, setSelectedProject] = useState("");

  const [declineTimesheetData, setDeclineTimesheetData] = useState<any>(null);
  const [approveTimesheetData, setApproveTimesheetData] = useState(null);

  const [columns] = useState([
    {
      accessorKey: "empId",
      header: "Emp ID",
      size: 40,
    },
    {
      accessorKey: "empName",
      header: "Emp Name",
      size: 40,
    },
    {
      accessorKey: "week",
      header: "Week",
      size: 40
    },
    {
      accessorKey: "task",
      header: "Task",
      size: 40,
    },
    {
      accessorKey: "project",
      header: "Project",
      size: 40,
    },
    {
      accessorKey: "mon",
      header: "Mon",
      size: 40,
    },
    {
      accessorKey: "tue",
      header: "Tue",
      size: 40,
    },
    {
      accessorKey: "wed",
      header: "Wed",
      size: 40,
    },
    {
      accessorKey: "thu",
      header: "Thu",
      size: 40,
    },
    {
      accessorKey: "fri",
      header: "Fri",
      size: 40,
    },
    {
      accessorKey: "sat",
      header: "Sat",
      size: 40,
    },
    {
      accessorKey: "sun",
      header: "Sun",
      size: 40,
    },
    {
      accessorKey: "total",
      header: "Total",
      size: 40,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 40,
      Cell: ({ cell }) => (
        <ButtonGroup orientation="vertical">
          <Button
            onClick={() => {
              dispatch(
                setDialog({
                  open: true,
                  dialogName: "ApproveTimesheet",
                })
              );
              setApproveTimesheetData({
                decision: "A",
                employeeProjectId: cell.row.original.employeeProjectId,
                timeSheetId: cell.row.original.timeSheetId,
                taskId: cell.row.original.taskId,
                comment: "",
              });
            }}
          >
            Accept
          </Button>
          <Button
            onClick={() => {
              dispatch(
                setDialog({
                  open: true,
                  dialogName: "DeclineTimesheet",
                })
              );
              setDeclineTimesheetData({
                decision: "R",
                employeeProjectId: cell.row.original.employeeProjectId,
                timeSheetId: cell.row.original.timeSheetId,
                taskId: cell.row.original.taskId,
                comment: "",
              });
            }}
          >
            Decline
          </Button>
        </ButtonGroup>
      ),
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchAllProjects());
  }, [dispatch]);

  const getWeekDaysHoursList = (childTSheet) => {
    const daysObj = {
      sun: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
    };
    let sum = 0;
    childTSheet.forEach((childSheet) => {
      sum += parseInt(childSheet.hours.toString());
      daysObj[Object.keys(daysObj)[new Date(childSheet.date).getDay()]] =
        childSheet.hours;
    });
    return { ...daysObj, total: sum };
  };

  useEffect(() => {
    console.log('timesheetListing',timesheetListing)
    if (timesheetListing) {
      const tableRows =
        timesheetListing?.map((timesheet) => {
          if (!timesheet?.childTimeSheets) return [];
          return (
            timesheet?.childTimeSheets?.map((childtimesheet) => ({
              empId: timesheet.employeeId,
              week: `${dayMonthYearFormat(new Date(timesheet.startDate), false)} - ${dayMonthYearFormat(new Date(timesheet.endDate), false)}`,
              empName: timesheet.employeeName,
              taskId: childtimesheet[0].taskId,
              task: childtimesheet[0].taskName,
              project: childtimesheet[0].projectName,
              ...getWeekDaysHoursList(childtimesheet),
              // total: timesheet.totalhours,
              employeeProjectId: childtimesheet[0].employeeProjectId,
              timeSheetId: childtimesheet[0].timeSheetId,
              status: childtimesheet[0].approvalStatus,
            })) ?? []
          );
        }) ?? [];
      // const tableRows = timesheetListing?.map((timesheet) => ({
      //   empId: timesheet.employeeId,
      //   empName: timesheet.employeeId.toString(),
      //   task: "task soon",
      //   project: "project soon",
      //   ...getWeekDaysHoursList(timesheet),
      //   total: timesheet.totalhours,
      //   childTimesheets: timesheet.childTimeSheets,
      //   employeeProjectId: timesheet.childTim,
      //   timeSheetId: timesheet.childTimeSheets[0].timeSheetId,
      // }));
      let list = [];
      if (tableRows) {
        for (let tempTableRows of tableRows) {
          if (tempTableRows) {
            for (let i = 0; i < tempTableRows.length; i++) {
              if (tempTableRows[i]) {
                list.push(tempTableRows[i]);
              }
            }
          }
        }
      }
      // const tempTableRows = tableRows[0];

      setRows(list);
    } else {
      setRows([]);
    }
  }, [dispatch, timesheetListing]);

  useEffect(() => {
      dispatch(
        fetchPmTimesheetList({
          projectId: parseInt(selectedProject),
        })
      );
      if(allProjects.length===0){
        dispatch(fetchAllPmProjects())
      }
  }, [dispatch, allProjects, selectedProject]);


  if (user.employeeInfo.roleId === 2) {
    return <Navigate to="/" />;
  }

  return (
    <Grid container spacing={1} minHeight="100vh" alignContent="start">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Stack direction="row" spacing={2} alignItems="center">
              {" "}
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                Select Project:{" "}
              </Typography>
              <Box>
                <FormControl
                  size="small"
                  sx={{
                    width: "300px",
                  }}
                >
                  <Select
                    sx={{
                      width: "300px",
                      height: "35px",
                      borderRadius: "8px",
                      fontSize: "12px",
                      position: "relative",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    variant="outlined"
                    value={selectedProject}
                    displayEmpty
                    onChange={(event: SelectChangeEvent) => {
                      setSelectedProject(event.target.value as string);
                    }}
                  >
                    <MenuItem value={""}>All</MenuItem>
                    {allProjects?.map((project) => (
                      <MenuItem value={project.projectId}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ fontSize: "15px", fontWeight: "700" }}>
        <MaterialReactTable
          muiTableBodyRowProps={{ hover: false }}
          muiTableProps={{
            sx: {
              tableLayout: "auto",
            },
          }}
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              "& td:nth-of-type(odd)": {
                backgroundColor: "#ECECEC",
              },
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              fontWeight: "normal",
              fontSize: "11px",
            },
          }}
          columns={columns}
          data={rows}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
          // renderDetailPanel={({ row }) => {
          //   return (
          //     <Box
          //       sx={{ background: "#ECECEC", padding: 2, borderRadius: "10px" }}
          //     >
          //       <Grid container>
          //         <Grid item xs={3}>
          //           <SubHeadingTypography>Project Name</SubHeadingTypography>
          //         </Grid>
          //         <Grid item xs={3}>
          //           <SubHeadingTypography>Task</SubHeadingTypography>
          //         </Grid>
          //         <Grid item xs={3}>
          //           <SubHeadingTypography>Total Hours</SubHeadingTypography>
          //         </Grid>
          //         <Grid item xs={3}>
          //           <SubHeadingTypography>Comment</SubHeadingTypography>
          //         </Grid>

          //         <Grid xs={12} sx={{ my: 1 }}></Grid>

          //         {row.original.childTimesheets?.map((childTimesheetData) => (
          //           <>
          //             <Grid item xs={3}>
          //               <SubItemTypography>
          //                 {childTimesheetData.projectName}
          //               </SubItemTypography>
          //             </Grid>
          //             <Grid item xs={3}>
          //               <SubItemTypography>
          //                 {childTimesheetData.taskName}
          //               </SubItemTypography>
          //             </Grid>
          //             <Grid item xs={3}>
          //               <SubItemTypography>
          //                 {childTimesheetData.hours}
          //               </SubItemTypography>
          //             </Grid>
          //             <Grid item xs={3}>
          //               <SubItemTypography>
          //                 {childTimesheetData.comment}
          //               </SubItemTypography>
          //             </Grid>
          //           </>
          //         )) ?? []}
          //       </Grid>
          //     </Box>
          //   );
          // }}

          //   renderTopToolbarCustomActions={CustomersTableActionBar}
          //   enableRowActions
          //   renderRowActionMenuItems={CustomersTableActionMenu}
        />
      </Grid>
      <DeclineTimesheetModal declineTimesheetData={declineTimesheetData} />
      <ApproveTimesheetModal approveTimesheetData={approveTimesheetData} />
    </Grid>
  );
};
