import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ICustomerSlice } from "./types";

const selectSelf = (state: RootState) => state;
const selectCustomer = createSelector(
  selectSelf,
  (state) => state.customer
);

export const selectIsCustomerLoading = createSelector(
  selectCustomer,
  (customer: ICustomerSlice) => customer.isLoading
);

export const selectCustomerList = createSelector(
  selectCustomer,
  (customer: ICustomerSlice) => customer.customerList
);

export const selectCountryList = createSelector(
  selectCustomer,
  (customer: ICustomerSlice) => customer.countryList
);
