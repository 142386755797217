import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IProjectSlice } from "./types";

const selectSelf = (state:RootState) => state;
const selectProject = createSelector(
  selectSelf,
  (state) => state.project
)

export const selectProjectList = createSelector(
  selectProject,
  (project: IProjectSlice ) => project.projectList
);

export const selectManagerList = createSelector(
  selectProject,
  (project: IProjectSlice ) => project.managerList
);

export const selectPriorityList = createSelector(
  selectProject,
  (project: IProjectSlice ) => project.priorityList
);

export const selectProjectStatusList = createSelector(
  selectProject,
  (project: IProjectSlice ) => project.projectStatusList
);