import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HonestWeekPicker } from "src/components/WeekPicker/WeekPicker";
import { selectTimesheetList } from "src/slices/timesheet/selectors";
import { fetchTimesheetList } from "src/slices/timesheet/thunks";
import { IBriefInfo, ITimesheet } from "src/slices/timesheet/types";
import { AppDispatch } from "src/store";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/constants";

const SubHeadingTypography = styled(Typography)(({ theme }) => ({
  color: "#1E1E1E",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
}));

const SubItemTypography = styled(Typography)(({ theme }) => ({
  color: "#1E1E1E",
  fontFamily: "Poppins",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
}));

const getColor = (cell) => {
  if (cell.getValue() === "Missing") {
    return "#1C2E8A";
  } else if (cell.getValue() === "Approved") {
    return "#1B9019";
  } else if (cell.getValue() === "Pending") {
    return "#126C88";
  } else if (cell.getValue() === "Rejected") {
    return "#FF1515";
  }
};

export const TimesheetListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedStatus, setSelectedStatus] = useState("All");
  // const [noFilter, setNoFilter] = useState(false);

  const [week, setWeek] = useState(
    null
    //   {
    //   firstDay: startOfWeek(new Date(), { weekStartsOn: 1 }),
    //   lastDay: endOfWeek(new Date(), { weekStartsOn: 1 }),
    // }
  );

  const timesheetListing: ITimesheet[] = useSelector(selectTimesheetList);
  const columns = [
    {
      accessorKey: "weekPeriod",
      header: "Week Period",
    },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <Typography
          sx={{
            color: getColor(cell),
          }}
        >
          {cell.getValue()}
        </Typography>
      ),
    },
    {
      accessorKey: "totalHours",
      header: "Total Hours",
    },
    {
      accessorKey: "assignTo",
      header: "Assign To",
    },
    {
      accessorKey: "openTimesheet",
      header: "Open Timesheet",
      Cell: ({ cell }) => (
        <IconButton
          onClick={() => {
            navigate(RoutePaths.WEELY_TIMESHEET, {
              state: {
                week: {
                  firstDay: new Date(cell.getValue().startDate),
                  lastDay: new Date(cell.getValue().endDate),
                },
              },
            });
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (!week) {
      if (selectedStatus === "All") {
        dispatch(fetchTimesheetList(null));
      } else {
        dispatch(fetchTimesheetList({ status: selectedStatus }));
      }
      return;
    }
    if (selectedStatus === "All") {
      dispatch(
        fetchTimesheetList({
          startDate: format(week.firstDay, "yyyy-MM-dd"),
          endDate: format(week.lastDay, "yyyy-MM-dd"),
        })
      );
    } else {
      dispatch(
        fetchTimesheetList({
          startDate: format(week.firstDay, "yyyy-MM-dd"),
          endDate: format(week.lastDay, "yyyy-MM-dd"),
          status: selectedStatus,
        })
      );
    }
  }, [dispatch, week, selectedStatus]);

  useEffect(() => {
    if (timesheetListing) {
      const tableRows = timesheetListing?.map((timesheet: ITimesheet) => ({
        weekPeriod: `${convertDate(timesheet.startDate)} - ${convertDate(
          timesheet.endDate
        )}`,
        totalHours: timesheet.totalhours,
        assignTo: timesheet.assignedTo?.join(", ") ?? "",
        childtimesheet: timesheet.childTimeSheets,
        briefInfo: timesheet.briefInfo,
        status: timesheet.overallStatus,
        openTimesheet: {
          timeSheetId: timesheet.timeSheetId,
          startDate: timesheet.startDate,
          endDate: timesheet.endDate,
        },
      }));
      setRows(tableRows);
    }
  }, [timesheetListing]);

  const convertDate = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let dt = new Date(date);
    return `${dt.getDate()} ${months[dt.getMonth()]}, ${dt.getFullYear()}`;
  };

  const onChange = (week) => {
    // setWeek(week);
  };

  return (
    <Grid container spacing={1} minHeight="100vh" alignContent="start">
      <Grid item xs={12}>
        <Grid container>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "10px",
            }}
          >
            {/* <FormControlLabel
              value="noFilter"
              control={
                <Checkbox
                  checked={noFilter}
                  onChange={(e) => {
                    setNoFilter(e.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="No Filter"
              labelPlacement="start"
              sx={{
                mr: 5,
              }}
            /> */}

            <>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Select Week:{" "}
                </Typography>
                <Box>
                  <HonestWeekPicker
                    week={week}
                    setWeek={setWeek}
                    onChange={onChange}
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                {" "}
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Select Status:{" "}
                </Typography>
                <Box>
                  <FormControl
                    size="small"
                    sx={{
                      width: "300px",
                    }}
                  >
                    <Select
                      sx={{
                        width: "300px",
                        height: "35px",
                        borderRadius: "8px",
                        fontSize: "12px",
                        position: "relative",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      variant="outlined"
                      value={selectedStatus}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedStatus(event.target.value);
                      }}
                    >
                      {[
                        "All",
                        "Missing",
                        "Draft",
                        "Pending Approval",
                        "Partially Rejected",
                        "Partially Approved",
                        "Approved",
                        "Rejected",
                      ].map((status) => (
                        <MenuItem value={status}>{status}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <HonestWeekPicker onChange={onChange} /> */}
                </Box>
              </Stack>
            </>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialReactTable
          columns={columns}
          data={rows}
          initialState={{
            density: "comfortable",
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
          renderDetailPanel={({ row }) => (
            <Box
              sx={{ background: "#ECECEC", padding: 2, borderRadius: "10px" }}
            >
              <Grid container>
                <Grid item xs={3}>
                  <SubHeadingTypography>Project Name</SubHeadingTypography>
                </Grid>
                <Grid item xs={3}>
                  <SubHeadingTypography>Task</SubHeadingTypography>
                </Grid>
                <Grid item xs={3}>
                  <SubHeadingTypography>Total Hours</SubHeadingTypography>
                </Grid>
                <Grid item xs={3}>
                  <SubHeadingTypography>Project Manager</SubHeadingTypography>
                </Grid>

                <Grid xs={12} sx={{ my: 1 }}></Grid>

                {row.original.briefInfo?.map(
                  (childTimesheetData: IBriefInfo) => (
                    <>
                      <Grid item xs={3}>
                        <SubItemTypography>
                          {childTimesheetData.projectName}
                        </SubItemTypography>
                      </Grid>
                      <Grid item xs={3}>
                        <SubItemTypography>
                          {childTimesheetData.taskName}
                        </SubItemTypography>
                      </Grid>
                      <Grid item xs={3}>
                        <SubItemTypography>
                          {childTimesheetData.totalHours}
                        </SubItemTypography>
                      </Grid>
                      <Grid item xs={3}>
                        <SubItemTypography>
                          {childTimesheetData.projectManager}
                        </SubItemTypography>
                      </Grid>
                    </>
                  )
                ) ?? []}
              </Grid>
            </Box>
          )}
          //   renderTopToolbarCustomActions={CustomersTableActionBar}
          //   enableRowActions
          //   renderRowActionMenuItems={CustomersTableActionMenu}
        />
      </Grid>
    </Grid>
  );
};
