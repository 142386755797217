import { createSlice } from "@reduxjs/toolkit";
import { IProject, ITask, ITimesheetSlice } from "./types";
import {
  approveRejectTimesheet,
  editTimesheet,
  exportReport,
  fetchAllEmpTimesheet,
  fetchAllPmProjects,
  fetchAllProjects,
  fetchAllTasks,
  fetchPmTimesheetList,
  fetchTimesheetList,
  saveTimesheet,
  sumbitTimesheet,
  unsumbitTimesheet,
} from "./thunks";
import { addDays, format } from "date-fns";

const initialState: ITimesheetSlice = {
  timesheetList: [],
  pmTimesheetList: [],
  allProjects: [],
  allPMProjects: [],
  allTasks: [],
  allEmpTimesheet:[],
  editTimesheetIndex: -1,
  isLoading: false,
  isError: false,
};

export const timesheetSlice = createSlice({
  name: "timesheet",
  initialState: initialState,
  reducers: {
    addNewTimesheet(state, action): void {
      if (state.timesheetList?.length > 0) {
        if (state.timesheetList[0].childTimeSheets) {
          if (
            state.timesheetList[0].childTimeSheets[0][0].childTimeSheetId === -1
          )
            return;

          state.timesheetList[0].childTimeSheets = [
            [
              format(action.payload.firstDay, "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 1), "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 2), "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 3), "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 4), "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 5), "yyyy-MM-dd"),
              format(action.payload.lastDay, "yyyy-MM-dd"),
            ].map((date) => ({
              childTimeSheetId: -1,
              timesheetId: state.timesheetList[0].timeSheetId,
              employeeProjectId: null,
              projectCode: null,
              projectName: null,
              projectStatus: null,
              projectPriority: null,
              projectManager: null,
              taskId: null,
              taskName: null,
              date: date,
              hours: 0,
              comment: null,
              approvalStatusId: null,
              approvalStatus: null,
              approverId: null,
              approvalDate: null,
              isLocked: false,
              id: null,
            })),
            ...state.timesheetList[0].childTimeSheets,
          ];
        } else {
          state.timesheetList[0].childTimeSheets = [
            [
              format(action.payload.firstDay, "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 1), "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 2), "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 3), "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 4), "yyyy-MM-dd"),
              format(addDays(action.payload.firstDay, 5), "yyyy-MM-dd"),
              format(action.payload.lastDay, "yyyy-MM-dd"),
            ].map((date) => ({
              childTimeSheetId: -1,
              timesheetId: state.timesheetList[0].timeSheetId,
              employeeProjectId: null,
              projectCode: null,
              projectName: null,
              projectStatus: null,
              projectPriority: null,
              projectManager: null,
              taskId: null,
              taskName: null,
              date: date,
              hours: 0,
              comment: null,
              approvalStatusId: null,
              approvalStatus: null,
              approverId: null,
              approvalDate: null,
              isLocked: false,
              id: null,
            })),
          ];
        }
      }
    },
    selectNewTask(state, action): void {
      const selectedTask: ITask = action.payload;
      for (
        let i = 0;
        i < state.timesheetList[0].childTimeSheets[0].length;
        i++
      ) {
        state.timesheetList[0].childTimeSheets[0][i].taskId = selectedTask.id;
        state.timesheetList[0].childTimeSheets[0][i].taskName =
          selectedTask.name;
      }
    },
    selectNewProject(state, action): void {
      const selectedProject: IProject = action.payload;
      for (
        let i = 0;
        i < state.timesheetList[0].childTimeSheets[0].length;
        i++
      ) {
        state.timesheetList[0].childTimeSheets[0][i].employeeProjectId =
          selectedProject.employeeProjectId;
        // state.timesheetList[0].childTimeSheets[0][i].projectManager =
        //   selectedProject.projectCode;
        state.timesheetList[0].childTimeSheets[0][i].projectCode =
          selectedProject.projectCode;
        state.timesheetList[0].childTimeSheets[0][i].projectName =
          selectedProject.name;
      }
    },
    setHoursForDay(state, action): void {
      if (
        state.timesheetList[0].childTimeSheets[action.payload.childsheetIndex][
          action.payload.singleChildsheetIndex
        ].date === format(action.payload.date, "yyyy-MM-dd")
      ) {
        const oldValue = state.timesheetList[0].childTimeSheets[action.payload.childsheetIndex][
          action.payload.singleChildsheetIndex
        ].hours;
        const reg = /^\d*(\.5)?$/;
        let value = action.payload.hours.toString();
        let newValue = 0; 
        if(!reg.test(value.toString())) {
          newValue = oldValue
          return
        }
        if(value[0]===0) newValue = value.substring(1)
        else if(value > 24) newValue=oldValue;
        else newValue = value
        state.timesheetList[0].childTimeSheets[action.payload.childsheetIndex][
          action.payload.singleChildsheetIndex
        ].hours = newValue;
      }
    },
    setEditTimesheetIndex(state, action): void {
      state.editTimesheetIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchTimesheetList
    builder.addCase(fetchTimesheetList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTimesheetList.fulfilled, (state, action) => {
      state.timesheetList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchTimesheetList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchPmTimesheetList
    builder.addCase(fetchPmTimesheetList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPmTimesheetList.fulfilled, (state, action) => {
      state.pmTimesheetList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchPmTimesheetList.rejected, (state) => {
      state.pmTimesheetList = [];
      state.isLoading = false;
      state.isError = true;
    });

    // fetchAllProjects
    builder.addCase(fetchAllProjects.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllProjects.fulfilled, (state, action) => {
      state.allProjects = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAllProjects.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    
    // fetchAllPmProjects
    builder.addCase(fetchAllPmProjects.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllPmProjects.fulfilled, (state, action) => {
      state.allPMProjects = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAllPmProjects.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    //fetchAllEmpTimesheet
    builder.addCase(fetchAllEmpTimesheet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllEmpTimesheet.fulfilled, (state, action) => {
      state.allEmpTimesheet = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAllEmpTimesheet.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchAllTasks
    builder.addCase(fetchAllTasks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllTasks.fulfilled, (state, action) => {
      state.allTasks = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAllTasks.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // approveRejectTimesheet
    builder.addCase(approveRejectTimesheet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveRejectTimesheet.fulfilled, (state, action) => {
      if (action.payload) {
        state.pmTimesheetList = [...state.pmTimesheetList.filter(tm => tm.employeeId !== action.payload.employeeId)];
      }
      state.isLoading = false;
    });
    builder.addCase(approveRejectTimesheet.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // saveTimesheet
    builder.addCase(saveTimesheet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveTimesheet.fulfilled, (state, action) => {
      if (action.payload) {
        // state.pmTimesheetList = [];
      }
      state.isLoading = false;
    });
    builder.addCase(saveTimesheet.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // editTimesheet
    builder.addCase(editTimesheet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editTimesheet.fulfilled, (state, action) => {
      if (action.payload) {
        state.editTimesheetIndex = -1;
      }
      state.isLoading = false;
    });
    builder.addCase(editTimesheet.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // sumbitTimesheet
    builder.addCase(sumbitTimesheet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sumbitTimesheet.fulfilled, (state, action) => {
      state.editTimesheetIndex = -1;
      state.isLoading = false;
    });
    builder.addCase(sumbitTimesheet.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    
    // unsumbitTimesheet
    builder.addCase(unsumbitTimesheet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(unsumbitTimesheet.fulfilled, (state, action) => {
      state.editTimesheetIndex = -1;
      state.isLoading = false;
    });
    builder.addCase(unsumbitTimesheet.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    
    // exportReport
    builder.addCase(exportReport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(exportReport.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(exportReport.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const {
  addNewTimesheet,
  selectNewTask,
  selectNewProject,
  setHoursForDay,
  setEditTimesheetIndex,
} = timesheetSlice.actions;
export const { reducer } = timesheetSlice;
