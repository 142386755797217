import { Grid, Button, MenuItem } from "@mui/material";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useState } from "react";
import { columns } from "./config/table-columns";
import { RoutePaths } from "src/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { deleteProject, fetchProjectListing } from "src/slices/project/thunk";
import { selectProjectList } from "src/slices/project/selectors";
import { IProject } from "src/slices/project/types";

const Projects = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [projectList, setProjectList] = useState<any[]>([]);
  const projectListing: IProject[] = useSelector(selectProjectList);

  useEffect(() => {
    dispatch(fetchProjectListing());
  }, [dispatch]);

  useEffect(() => {
    if (projectListing) {
      setProjectList([...projectListing]);
    }
  }, [projectListing]);

  const updateStatus = (value: boolean, projectId: string) => {
    console.log("update date", projectId, value);
    dispatch(
      deleteProject({
        isActive: value,
        projectId,
      })
    ).then(() => {
      dispatch(fetchProjectListing());
    });;
  };

  return (
    <Grid container spacing={1} minHeight="100vh" alignContent="start">
      <Grid item xs={12}>
        <MaterialReactTable
          columns={columns({ updateStatus })}
          data={projectList}
          initialState={{
            density: "comfortable",
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
          enableRowActions
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key={0}
              onClick={() => {
                navigate(RoutePaths.CREATE_EDIT_PROJECT, {
                  state: {
                    project: row.original,
                  },
                });
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              Edit
            </MenuItem>,
            <MenuItem
            key={1}
            onClick={() => {
              closeMenu();
              dispatch(
                deleteProject({
                  projectId: row.original.projectId,
                  isActive: !row.original.isActive,
                })
              ).then(() => {
                dispatch(fetchProjectListing());
              });
            }}
            sx={{ m: 0 }}
          >
            {row.original.isActive ? "Disable" : "Enable"}
          </MenuItem>,
          ]}
          renderTopToolbarCustomActions={({ table }) => (
            <Button
              onClick={() => {
                navigate(RoutePaths.CREATE_EDIT_PROJECT);
              }}
            >
              Create Project
            </Button>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Projects;
