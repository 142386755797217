import { createSlice } from "@reduxjs/toolkit";
import { ICustomerSlice } from "./types";
import // fetchAllProjects,
// fetchAllTasks,
"./thunks";
// import { addDays, format } from "date-fns";
import { createCustomer, fetchCountryList, fetchCustomerList } from "./thunks";

const initialState: ICustomerSlice = {
  customerList: [],
  countryList: [],
  isLoading: false,
  isError: false,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    addCustomer(state, action): void {},
  },
  extraReducers: (builder) => {
    // fetchCountryList
    builder.addCase(fetchCountryList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCountryList.fulfilled, (state, action) => {
      state.countryList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCountryList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchCustomerList
    builder.addCase(fetchCustomerList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCustomerList.fulfilled, (state, action) => {
      state.customerList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCustomerList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // createCustomer
    builder.addCase(createCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      // state.customerList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createCustomer.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { addCustomer } = customerSlice.actions;
export const { reducer } = customerSlice;
