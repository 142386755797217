import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { DrawerNavigationList } from "./components/DrawerNavigationList";
import { useEffect, useState } from "react";
import logo from "src/icons/logo.png";
import CustomLoader from "./components/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { Copyright } from "./components/Copyright";
import { AppBar } from "./components/AppBar";
import { AppDrawer } from "./components/AppDrawer";
import { RoutePaths, Slices } from "./constants";
import CToastInfoDialog from "./components/CToastInfoDialog";
import usePageTitle from "./hooks/usePageTitle";
import { AppDispatch, RootState } from "./store";
import { selectIsUserLoggedIn } from "./slices/auth/selectors";
import { logout, refeshToken } from "./slices/auth/thunks";
import { setLoading } from "./slices/commonSlice";
import { selectIsTimesheetLoading } from "./slices/timesheet/selectors";
import { selectIsCustomerLoading } from "./slices/customer/selectors";
import { selectIsEmployeeLoading } from "./slices/employee/selectors";

export let sessionTimeoutDispatcher = null;
// let isSessionTimoutMessageShown = false;
const drawerWidth: number = 240;
const MINUTE_MS = 60000;

function DashboardContent() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(refeshToken());
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [dispatch]);

  async function sessionTimout(message = "") {
    // if (!isSessionTimoutMessageShown) {
    //   isSessionTimoutMessageShown = true;
    //   setModalMessage(message);
    //   handleOpen();
    // }
  }
  sessionTimeoutDispatcher = sessionTimout;

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isLoggedIn = useSelector(selectIsUserLoggedIn);

  const headingTitle = usePageTitle(location.pathname);

  if (!isLoggedIn) {
    // Redirect the user to the home page
    return <Navigate to={RoutePaths.LOGIN} />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="absolute"
        elevation={0}
        open={open}
        drawerWidth={drawerWidth}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {headingTitle}
          </Typography>
          <IconButton
            color="inherit"
            onClick={async () => {
              setLoading(true);
              await dispatch(logout());
              setLoading(false);
              // localStorage.clear();
              // window.location.reload();
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AppDrawer variant="permanent" open={open} drawerWidth={drawerWidth}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="logo"
            height={50}
            justifySelf="center"
          />
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <DrawerNavigationList />
          {/* <Divider sx={{ my: 1 }} /> */}
          {/* {secondaryListItems} */}
        </List>
      </AppDrawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" style={{ padding: 0 }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Outlet />
          </Paper>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

export default function App() {
  const { isLoading } = useSelector(
    (state: RootState) => state[Slices.COMMON_REDUCER]
  );
  const isTimesheetLoading = useSelector(selectIsTimesheetLoading);
  const isCustomerLoading = useSelector(selectIsCustomerLoading);
  const isEmployeeLoading = useSelector(selectIsEmployeeLoading);

  return (
    <>
      {isLoading ||
      isTimesheetLoading ||
      isCustomerLoading ||
      isEmployeeLoading ? (
        <CustomLoader />
      ) : null}
      <CToastInfoDialog />
      <DashboardContent />
    </>
  );
}
