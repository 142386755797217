import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./pages/ErrorPage";
import { Login, Verification } from "./pages/auth";
// import { ProtectedRoute } from "./pages/ProtectedRoute";
import { RoutePaths } from "./constants";
import CToastInfoDialog from "./components/CToastInfoDialog";
import { WeeklyWorksheet } from "./pages/weekly-worksheet";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import { TimesheetListing } from "./pages/timesheet-listing";
import { ApprovalTimesheet } from "./pages/approval-timesheet";
import { CreateCustomer } from "./pages/customer/create-customer";
import { CustomerListing } from "./pages/customer/cutomer-listing";
import { CreateEmployee } from "./pages/employee/create-employee";
import { EmployeeListing } from "./pages/employee/employee-listing";
import { Reports } from "./pages/reports";
import Projects from "./pages/projects";
import CreateProject from "./pages/projects/create-project";
import EmpProjectAssignment from "./pages/emp-project-assignment";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <TimesheetListing />
          </ProtectedRoute>
        ),
      },
      {
        path: "/weekly-timesheet",
        element: (
          <ProtectedRoute>
            <WeeklyWorksheet />
          </ProtectedRoute>
        ),
      },
      {
        path: "/approval-timesheet",
        element: (
          <ProtectedRoute>
            <ApprovalTimesheet />
          </ProtectedRoute>
        ),
      },
      {
        path: "/create-edit-customer",
        element: (
          <ProtectedRoute>
            <CreateCustomer />
          </ProtectedRoute>
        )
      },
      {
        path: "/customer-listing",
        element: (
          <ProtectedRoute>
            <CustomerListing />
          </ProtectedRoute>
        )
      },
      {
        path: "/create-edit-employee",
        element: (
          <ProtectedRoute>
            <CreateEmployee />
          </ProtectedRoute>
        )
      },
      {
        path: "/employee-listing",
        element: (
          <ProtectedRoute>
            <EmployeeListing />
          </ProtectedRoute>
        )
      },
      {
        path: RoutePaths.EMP_PROJECT_LISTING,
        element: (
          <ProtectedRoute>
            <EmpProjectAssignment />
          </ProtectedRoute>
        )
      },
      {
        path: RoutePaths.REPORTS,
        element: (
          <ProtectedRoute>
            <Reports/>
          </ProtectedRoute>
        )
      },
      {
        path: RoutePaths.PROJECTS,
        element: (
          <ProtectedRoute>
            <Projects/>
          </ProtectedRoute>
        )
      },
      {
        path: RoutePaths.CREATE_EDIT_PROJECT,
        element: (
          <ProtectedRoute>
            <CreateProject/>
          </ProtectedRoute>
        )
      },
    ],
  },
  {
    path: RoutePaths.LOGIN,
    element: (
      <>
        <CToastInfoDialog />
        <Login />
      </>
    ),
  },
  {
    path: RoutePaths.VERIFICATION,
    element: (
      <>
        <CToastInfoDialog />
        <Verification />
      </>
    ),
  },
  {
    path: "/*",
    element: <ErrorPage />,
  },
]);
