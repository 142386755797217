export const columns = [
  {
    accessorKey: "employeeName",
    header: "Employee Name",
    size: 40,
  },
  {
    accessorKey: "projectName",
    header: "Project Name",
    size: 40,
  },
  {
    accessorKey: "startDate",
    header: "Start Date",
    size: 40,
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    size: 40,
  },
  // {
  //   accessorKey: "taskName",
  //   header: "Task",
  //   size: 40,
  // },
  // {
  //   accessorKey: "approvalstatusname",
  //   header: "Approval Status",
  //   size: 40,
  // },
  // {
  //   accessorKey: "hours",
  //   header: "Hours",
  //   size: 40,
  // },
  // {
  //   accessorKey: "submissionDate",
  //   header: "Submission Date",
  //   size: 40,
  // },
 
];