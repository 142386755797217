import {
  Button,
  Grid,
  MenuItem,
  Typography,
  //   useMediaQuery,
  //   useTheme,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/constants";
import { ICustomer } from "src/slices/customer/types";
import { selectCustomerList } from "src/slices/customer/selectors";
import { deleteCustomer, fetchCustomerList } from "src/slices/customer/thunks";
import { format } from "date-fns";

const getColor = (cell) => {
  if (cell.getValue()) {
    return "green";
  } else {
    return "red";
  }
};

export const CustomerListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  //   const theme = useTheme();
  //   const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const timesheetListing: ICustomer[] = useSelector(selectCustomerList);
  const columns = [
    {
      accessorKey: "code",
      header: "Code",
      size: 40,
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "contact",
      header: "Contact",
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 40,
      Cell: ({ cell }) => (
        <Typography
          sx={{
            color: getColor(cell),
          }}
        >
          {cell.getValue() ? "Active" : "Inactive"}
        </Typography>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      size: 40,
    },
  ];
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (timesheetListing) {
      const tableRows = timesheetListing?.map((customer: ICustomer) => ({
        code: customer.id,
        name: customer.name,
        email: customer.email,
        contact: customer.contact,
        status: customer.isActive,
        createdAt: format(new Date(customer.createdAt), "dd-MM-yyyy"),
        editCustomer: customer,
        toggleCustomer: customer,
        customer: customer,
      }));
      setRows(tableRows);
    }
  }, [timesheetListing]);

  return (
    <Grid container spacing={1} minHeight="100vh" alignContent="start">
      <Grid item xs={12}>
        <MaterialReactTable
          columns={columns}
          data={rows}
          initialState={{
            density: "comfortable",
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
          enableRowActions
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key={0}
              onClick={() => {
                navigate(RoutePaths.CREATE_EDIT_CUSTOMER, {
                  state: {
                    customer: row.original.customer,
                  },
                });
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              Edit
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => {
                closeMenu();
                dispatch(
                  deleteCustomer({
                    customerId: row.original.customer.id,
                    isActive: !row.original.customer.isActive,
                  })
                ).then(() => {
                  dispatch(fetchCustomerList());
                });
              }}
              sx={{ m: 0 }}
            >
              {row.original.customer.isActive ? "Disable" : "Enable"}
            </MenuItem>,
          ]}
          renderTopToolbarCustomActions={({ table }) => (
            <Button
              onClick={() => {
                navigate(RoutePaths.CREATE_EDIT_CUSTOMER);
              }}
            >
              Create Customer
            </Button>
          )}
        />
      </Grid>
    </Grid>
  );
};
