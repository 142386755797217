import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormikControl from "src/components/formik/FormikControl";
import { RoutePaths } from "src/constants";
import { ContainedButton } from "src/components/button-group";
import logo from "src/icons/logo.png";
import background from "src/icons/bg.jpeg";
import { AppDispatch } from "src/store";
import { ForgetPasswordModal } from "../forget-password-modal";
import { login } from "src/slices/auth/thunks";
import {
  selectIsUserLoggedIn,
  selectUserEmail,
} from "src/slices/auth/selectors";

export const Login = () => {
  const formikRef = useRef();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const userEmail = useSelector(selectUserEmail);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const validationSchemaModel = Yup.object({
    email: Yup.string().email().required("Email is required"),
  });

  useEffect(() => {
    if (userEmail) {
      navigate(RoutePaths.VERIFICATION);
    }
  }, [navigate, userEmail]);


  const loginAPI = async (email: string) => {
    setIsSubmitted(true);
    await dispatch(login(email));
    setIsSubmitted(false);
  };

  if (isLoggedIn) {
    // Redirect the user to the home page
    return <Navigate to="/" />;
  }

  return (
    <Container
      style={{
        margin: 0,
        padding: 0,
      }}
      maxWidth={false}
      sx={{ margin: 0, padding: 0, height: "100%" }}
      disableGutters
    >
      <CssBaseline />
      <Box
        maxWidth={1}
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: `url(${background})`,
          backgroundRepeat: "no-repeat",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              sx={{
                maxHeight: "710px",
                height: "80%",
                maxWidth: "576px",
                minWidth: "400px",
                borderRadius: "12px",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.40)",
                display: "flex",
                padding: "50px",
              }}
            >
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchemaModel}
                innerRef={formikRef}
                onSubmit={async (values) => {
                  loginAPI(values.email);
                }}
              >
                {({ touched, errors, getFieldProps }) => (
                  <Form id="Login">
                    <Grid container spacing={3} textAlign="center">
                      <Grid item xs={12}>
                        <img src={logo} alt="logo" />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#1E1E1E",
                            fontFamily: "Poppins",
                            fontSize: "35px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                          }}
                        >
                          Welcome
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#1E1E1E",
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          Please login to continue
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormikControl
                          control="InputField"
                          name="email"
                          label="Email"
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          {...getFieldProps("email")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ContainedButton
                          disabled={isSubmitted}
                          sx={{
                            width: "241px",
                            height: "50px",
                            borderRadius: "10px",
                            color: "#FFF",
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </ContainedButton>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
        <ForgetPasswordModal />
      </Box>
    </Container>
  );
};
