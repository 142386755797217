import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ContainedButton } from "src/components/button-group";
import logo from "src/icons/logo.png";
import background from "src/icons/bg.jpeg";
import { AppDispatch } from "src/store";
import { ForgetPasswordModal } from "../forget-password-modal";
import { verifyOtp } from "src/slices/auth/thunks";
import {
  selectIsUserLoggedIn,
  selectUserEmail,
} from "src/slices/auth/selectors";
import { RoutePaths } from "src/constants";

export const Verification = () => {
  const formikRef = useRef();
  const itemsRef = useRef([]);
  const dispatch = useDispatch<AppDispatch>();

  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const userEmail = useSelector(selectUserEmail);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setIsResendDisabled(false);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      setIsSubmitted(false);
    }
  }, [minutes, seconds]);

  const validationSchemaModel = Yup.object({
    code: Yup.array().of(Yup.string().required("Code is required")),
  });

  const verificationAPI = async (otp: string) => {
    setMinutes(0);
    setSeconds(0);
    setIsSubmitted(true);
    await dispatch(verifyOtp(otp));
    setIsSubmitted(false);
  };

  if (isLoggedIn) {
    // Redirect the user to the home page
    return <Navigate to="/" />;
  }

  if (!userEmail) {
    // Redirect the user to the login page
    return <Navigate to={RoutePaths.LOGIN} />;
  }

  return (
    <Container
      style={{
        margin: 0,
        padding: 0,
      }}
      maxWidth={false}
      sx={{ margin: 0, padding: 0, height: "100%" }}
      disableGutters
    >
      <CssBaseline />
      <Box
        maxWidth={1}
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: `url(${background})`,
          backgroundRepeat: "no-repeat",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              sx={{
                maxHeight: "710px",
                height: "80%",
                maxWidth: "576px",
                minWidth: "400px",
                borderRadius: "12px",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.40)",
                display: "flex",
                padding: "50px",
              }}
            >
              <Formik
                initialValues={{
                  code: ["", "", "", ""],
                }}
                validationSchema={validationSchemaModel}
                innerRef={formikRef}
                onSubmit={async (values) => {
                  verificationAPI(values.code.join(""));
                }}
              >
                {({ values, touched, errors, handleBlur, setFieldValue }) => (
                  <Form id="Verification">
                    <Grid container spacing={3} textAlign="center">
                      <Grid item xs={12}>
                        <img src={logo} alt="logo" />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#1E1E1E",
                            fontFamily: "Poppins",
                            fontSize: "28px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                          }}
                        >
                          Verification
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#1E1E1E",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          4 digit OTP sent to {userEmail}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#1E1E1E",
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          Please enter OTP here
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "grid",
                            columnGap: "16px",
                            gridTemplateColumns: "repeat(4, 1fr)",
                            pt: 1,
                            height: "0.058%",
                          }}
                        >
                          {[1, 2, 3, 4].map((_, i) => (
                            <TextField
                              error={Boolean(
                                Array.isArray(touched.code) &&
                                  touched.code.length === 4 &&
                                  errors.code
                              )}
                              fullWidth
                              inputRef={(el) => (itemsRef.current[i] = el)}
                              // eslint-disable-next-line react/no-array-index-key
                              key={`code-${i}`}
                              name={`code[${i}]`}
                              onBlur={handleBlur}
                              onKeyDown={(event) => {
                                if (event.code === "ENTER") {
                                  if (values.code[i]) {
                                    setFieldValue(`code[${i}]`, "");
                                    return;
                                  }

                                  if (i > 0) {
                                    setFieldValue(`code[${i}]`, "");
                                    itemsRef.current[i - 1].focus();
                                    return;
                                  }
                                }

                                if (Number.isInteger(parseInt(event.key, 10))) {
                                  setFieldValue(`code[${i}]`, event.key);

                                  if (i < 3) {
                                    itemsRef.current[i + 1].focus();
                                  }
                                }
                              }}
                              onPaste={(event) => {
                                const paste =
                                  event.clipboardData.getData("text");
                                const pasteArray = paste.split("");

                                if (pasteArray.length !== 4) {
                                  return;
                                }

                                let valid = true;

                                pasteArray.forEach((x) => {
                                  if (!Number.isInteger(parseInt(x, 10))) {
                                    valid = false;
                                  }
                                });

                                if (valid) {
                                  setFieldValue("code", pasteArray);
                                  itemsRef.current[3].focus();
                                }
                              }}
                              value={values.code[i]}
                              sx={{
                                display: "inline-block",
                                textAlign: "center",
                                "& .MuiInputBase-input": {
                                  textAlign: "center",
                                },
                                "& .MuiInputLabel-root": { color: "#626262" },
                                "& .MuiOutlinedInput-root": {
                                  "& > fieldset": { borderColor: "#454545" },
                                },
                              }}
                              variant="outlined"
                              InputProps={{ sx: { borderRadius: "5rem" } }}
                            />
                          ))}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          {minutes === 0 && seconds === 0 ? null : (
                            <h1>
                              {" "}
                              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                            </h1>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <ContainedButton
                          disabled={isSubmitted || !isResendDisabled}
                          sx={{
                            // width: "241px",
                            height: "50px",
                            borderRadius: "10px",
                            color: "#FFF",
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </ContainedButton>
                      </Grid>
                      <Grid item xs={6}>
                        <ContainedButton
                          disabled={isResendDisabled}
                          sx={{
                            // width: "241px",
                            height: "50px",
                            borderRadius: "10px",
                            color: "#FFF",
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                          fullWidth
                          onClick={()=>{
                            setIsResendDisabled(true);
                            setMinutes(5);
                          }}
                        >
                          Resend OTP
                        </ContainedButton>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
        <ForgetPasswordModal />
      </Box>
    </Container>
  );
};
