import React, { useEffect } from 'react'
import {
Button,
Grid,
MenuItem,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useState } from "react";
import { columns } from './table-config';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { fetchEmpProjectList } from 'src/slices/employee-project/thunk';
import { selectEmpProjectList } from 'src/slices/employee-project/selectors';
import { IEmpProject } from 'src/slices/employee-project/types';
import CreateEditEmpProject from './components/create-edit-emp-project';

const EmpProjectAssignment = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [projectList, setProjectList] = useState<any[]>([]);
  const [dataTobeEdit, setdataTobeEdit] = useState<IEmpProject>(null);
  const [isModalOpen, setisModalOpen] = useState<boolean>(false);
  const allEmpProjectList: IEmpProject[] = useSelector(selectEmpProjectList);

  useEffect(() => {
    dispatch(fetchEmpProjectList());
  }, [dispatch]);

  useEffect(() => {
    if (allEmpProjectList) {
      console.log('allEmpProjectList',allEmpProjectList)
      setProjectList([...allEmpProjectList]);
    }
  }, [allEmpProjectList]);

  return (
    <>
      <Grid container spacing={1} minHeight="100vh" alignContent="start">
        <Grid item xs={12} sx={{ fontSize: "15px", fontWeight: "700" }}>
          <MaterialReactTable
            muiTableBodyRowProps={{ hover: false }}
            initialState={{
              density: "comfortable",
              pagination: { pageIndex: 0, pageSize: 10 },
            }}
            enableRowActions
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <MenuItem
                key={0}
                onClick={() => {
                  setisModalOpen(true);
                  setdataTobeEdit({...row.original})
                  closeMenu();
                }}
                sx={{ m: 0 }}
              >
                Edit
              </MenuItem>,
            //   <MenuItem
            //   key={1}
            //   onClick={() => {
            //     closeMenu();
            //     // dispatch(
            //     //   deleteProject({
            //     //     projectId: row.original.projectId,
            //     //     isActive: !row.original.isActive,
            //     //   })
            //     // ).then(() => {
            //     //   dispatch(fetchProjectListing());
            //     // });
            //   }}
            //   sx={{ m: 0 }}
            // >
            //   {row.original.isActive ? "Disable" : "Enable"}
            // </MenuItem>,
            ]}
            columns={columns}
            data={projectList}
            renderTopToolbarCustomActions={({ table }) => (
              <Button
                onClick={() => {
                  setisModalOpen(true)
                }}
              >
                Assign Project
              </Button>
            )}
          />
        </Grid>
      </Grid>
      <CreateEditEmpProject open={isModalOpen} handleClose={()=>setisModalOpen(false)} data={dataTobeEdit}/>
    </>
  );
}

export default EmpProjectAssignment