import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useRef } from "react";
// import { useDispatch } from "react-redux";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
// import { AppDispatch } from "src/store";

export const ForgetPassword = ({ dialogName }) => {
  // const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);

  // Form Inital Values
  const formInitialValue = {
    email: "",
  };

  return (
    <Box sx={{ width: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={formInitialValue}
            innerRef={formikRef}
            onSubmit={(_) => {
              //   onClickSubmit(values);
            }}
          >
            {({ touched, errors, getFieldProps }) => (
              <Form id={dialogName}>
                {/* Complaint info */}
                <Grid container spacing={1} pb={3}>
                  <Grid item xs={12}>
                    <CTypography
                      sx={{
                        color: "#1E1E1E",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      Enter Your Email Below
                    </CTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      control="InputField"
                      name="email"
                      label={"Enter Email Here"}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      {...getFieldProps("email")}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};
