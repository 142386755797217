import { Button, Grid, MenuItem, Typography } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/constants";
import { IEmployee } from "src/slices/employee/types";
import { selectEmployeeList } from "src/slices/employee/selectors";
import { deleteEmployee, fetchEmployeeList } from "src/slices/employee/thunks";
import { format } from "date-fns";

const getColor = (cell) => {
  if (cell.getValue()) {
    return "green";
  } else {
    return "red";
  }
};

export const EmployeeListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const timesheetListing: IEmployee[] = useSelector(selectEmployeeList);
  const columns = [
    {
      accessorKey: "code",
      header: "Code",
      size: 40,
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "role",
      header: "Role",
    },
    {
      accessorKey: "cnic",
      header: "CNIC",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "contact",
      header: "Contact",
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 40,
      Cell: ({ cell }) => (
        <Typography
          sx={{
            color: getColor(cell),
          }}
        >
          {cell.getValue() ? "Active" : "Inactive"}
        </Typography>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      size: 40,
    },
  ];
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchEmployeeList());
  }, [dispatch]);

  useEffect(() => {
    if (timesheetListing) {
      const tableRows = timesheetListing?.map((employee: IEmployee) => ({
        code: employee.employeeId,
        name: employee.firstName + " " + employee.lastName,
        role: employee.employeeRole,
        cnic: employee.CNIC,
        email: employee.email,
        contact: employee.contact,
        status: employee.isActive,
        createdAt: format(new Date(employee.createdAt), "dd-MM-yyyy"),
        editEmployee: employee,
        toggleEmployee: employee,
        employee: employee,
      }));
      setRows(tableRows);
    }
  }, [timesheetListing]);

  return (
    <Grid container spacing={1} minHeight="100vh" alignContent="start">
      <Grid item xs={12}>
        <MaterialReactTable
          columns={columns}
          data={rows}
          initialState={{
            density: "comfortable",
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
          enableRowActions
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key={0}
              onClick={() => {
                navigate(RoutePaths.CREATE_EDIT_EMPLOYEE, {
                  state: {
                    employee: row.original.employee,
                  },
                });
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              Edit
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => {
                closeMenu();
                dispatch(
                  deleteEmployee({
                    employeeId: row.original.employee.employeeId,
                    isActive: !row.original.employee.isActive,
                  })
                ).then(() => {
                  dispatch(fetchEmployeeList());
                });
              }}
              sx={{ m: 0 }}
            >
              {row.original.employee.isActive ? "Disable" : "Enable"}
            </MenuItem>,
          ]}
          renderTopToolbarCustomActions={({ table }) => (
            <Button
              onClick={() => {
                navigate(RoutePaths.CREATE_EDIT_EMPLOYEE);
              }}
            >
              Create Employee
            </Button>
          )}
        />
      </Grid>
    </Grid>
  );
};
