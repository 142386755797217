import CTypography from "./CTypography";

export function Copyright(props: any) {
  return (
    <CTypography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Timesheet Managment"}{" "}
      {new Date().getFullYear()}
      {"."}
    </CTypography>
  );
}
