import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
import { ContainedButton, OutlinedButton } from "src/components/button-group";
import {
  createCustomer,
  editCustomer,
  fetchCountryList,
} from "src/slices/customer/thunks";
import { AppDispatch } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { selectCountryList } from "src/slices/customer/selectors";
import { ICustomer } from "src/slices/customer/types";

interface IFormikValues {
  customerCode: string;
  name: string;
  gender: string;
  // status: string;
  contact: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: number;
  // isRecurring: boolean;
}

export function CreateCustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { state } = useLocation();

  const formikRef = useRef(null);

  const countryList = useSelector(selectCountryList);

  // Form Inital Values
  const formInitialValue: IFormikValues = {
    customerCode: "",
    name: "",
    gender: "M",
    // status: "Active",
    contact: "",
    email: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: 166,
    // isRecurring: false,
  };

  // Form Fields Validation
  const validationSchemaModel = Yup.object().shape({
    contact: Yup.string().required("You must insert a contact number"),
    name: Yup.string().matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      "Name should be alphabetic"
    ),
    city: Yup.string().matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      "City should be alphabetic"
    ),
    state: Yup.string().matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      "State should be alphabetic"
    ),
    email:Yup.string().email('Email should be valid'),
    postalCode: Yup.number().typeError("Postal code should be numeric")
  });

  // Click Events
  const onClickSubmit = async (values) => {
    if (state?.customer) {
      dispatch(
        editCustomer({
          ...values,
          customerId: state.customer.id,
          isActive: state.customer.isActive,
          isRecurring: state.customer.isRecurring,
        })
      ).then(() => {
        navigate(-1);
      });
      return;
    }

    dispatch(
      createCustomer({
        ...values,
        customerCode: "PK-" + values.customerCode,
      })
    ).then(() => {
      navigate(-1);
    });
  };

  useEffect(() => {
    if (countryList.length === 0) {
      dispatch(fetchCountryList());
    }
  }, [countryList, dispatch]);

  useEffect(() => {
    if (state?.customer) {
      const customer: ICustomer = state.customer;
      formikRef.current.setFieldValue("customerCode", customer.customerCode);
      formikRef.current.setFieldValue("name", customer.name);
      formikRef.current.setFieldValue("gender", customer.gender);
      formikRef.current.setFieldValue("email", customer.email);
      formikRef.current.setFieldValue("contact", customer.contact);
      formikRef.current.setFieldValue("address", customer.address);
      formikRef.current.setFieldValue("city", customer.city);
      formikRef.current.setFieldValue("state", customer.state);
      formikRef.current.setFieldValue("postalCode", customer.postalCode);
      formikRef.current.setFieldValue("country", customer.country);
    }
  }, [state]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Formik
          initialValues={formInitialValue}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={(values) => {
            onClickSubmit(values);
          }}
        >
          {({ values, touched, errors, setFieldValue, getFieldProps }) => (
            <Form id="CreateCustomer">
              {/* Personal Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Personal Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    disabled={state?.customer}
                    name="customerCode"
                    label={"Customer Code"}
                    error={Boolean(touched.customerCode && errors.customerCode)}
                    helperText={touched.customerCode && errors.customerCode}
                    {...getFieldProps("customerCode")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="name"
                    label={"Name"}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    {...getFieldProps("name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="gender"
                    label="Gender"
                    options={[
                      {
                        label: "Male",
                        value: "M",
                      },
                      {
                        label: "Female",
                        value: "F",
                      },
                    ]}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                    {...getFieldProps("gender")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="email"
                    label="Email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    {...getFieldProps("email")}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="status"
                    label="Status"
                    options={[
                      {
                        label: "Active",
                        value: "Active",
                      },
                      {
                        label: "Inactive",
                        value: "Inactive",
                      },
                    ]}
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                    {...getFieldProps("status")}
                  />
                </Grid> */}
              </Grid>

              {/* Contact Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Contact Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="contact"
                    label="Contact"
                    error={Boolean(touched.contact && errors.contact)}
                    helperText={touched.contact && errors.contact}
                    {...getFieldProps("contact")}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormikControl
                    required
                    control="InputField"
                    name="address"
                    label="Address"
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    {...getFieldProps("address")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="InputField"
                    name="city"
                    label="City"
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                    {...getFieldProps("city")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="InputField"
                    name="state"
                    label="State"
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                    {...getFieldProps("state")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="InputField"
                    name="postalCode"
                    label="Postal Code"
                    error={Boolean(touched.postalCode && errors.postalCode)}
                    helperText={touched.postalCode && errors.postalCode}
                    {...getFieldProps("postalCode")}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="InputField"
                    name="country"
                    label="Country"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    {...getFieldProps("country")}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    required
                    control="SelectField"
                    name="country"
                    label="Country"
                    options={countryList.map((country) => ({
                      label: country.name,
                      value: country.id,
                    }))}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    {...getFieldProps("country")}
                  />
                </Grid>
              </Grid>

              {/* SUBMIT FORM */}
              <Grid container spacing={1}>
                <Grid item>
                  <ContainedButton
                    // onClick={() => {
                    //   onClickSubmit(values);
                    // }}
                    type="submit"
                  >
                    SUBMIT
                  </ContainedButton>
                </Grid>
                <Grid item>
                  <OutlinedButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    CANCEL
                  </OutlinedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
