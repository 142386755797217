import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IEmpProjectSlice } from "./types";

const selectSelf = (state:RootState) => state;
const selectProject = createSelector(
  selectSelf,
  (state) => state.empProject
)

export const selectEmpProjectList = createSelector(
  selectProject,
  (project: IEmpProjectSlice ) => project.employeeProjectList
);