import { createSlice } from "@reduxjs/toolkit";
import { IEmpProjectSlice } from "./types";
import { createEmpProejct, fetchEmpProjectList, updateEmpProejct } from "./thunk";

const initialState: IEmpProjectSlice = {
  employeeProjectList: [],
  isLoading: false,
  isError: false,
};

export const projectSlice = createSlice({
  name:'Project',
  initialState,
  reducers:{
  },
  extraReducers: (builder) => {
    //fetch project list
    builder.addCase(fetchEmpProjectList.pending, (state)=>{
      state.isLoading = false;
    });
    builder.addCase(fetchEmpProjectList.fulfilled, (state, action) => {
      state.employeeProjectList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchEmpProjectList.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    })
    
    //createProject
    builder.addCase(createEmpProejct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createEmpProejct.fulfilled, (state, action) => {
      // state.employeeList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createEmpProejct.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    
    //editempProject
    builder.addCase(updateEmpProejct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateEmpProejct.fulfilled, (state, action) => {
      // state.employeeList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateEmpProejct.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

  }
})

export const { reducer } = projectSlice;