import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { logout } from "../auth/thunks";
import { EmpProjectAPIService } from "src/constants";
import { IEmpProjectPayload } from "./types";

export const fetchEmpProjectList = createAsyncThunk(
  "project/empProjectList",
  async(_ ,{dispatch}) => {
    try {
      const response = await sendRequestJSON(
        null,
        EmpProjectAPIService.GET_EMP_PROJECT_LIST,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
)

export const createEmpProejct = createAsyncThunk(
  "project/createEmpProejct",
  async (payload: IEmpProjectPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        EmpProjectAPIService.ADD_EMP_PROJECT,
        "post"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const updateEmpProejct = createAsyncThunk(
  "project/updateEmpProejct",
  async (payload: IEmpProjectPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        EmpProjectAPIService.EDIT_EMP_PROJECT,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);