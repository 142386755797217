import { useState, useEffect } from "react";
import { RoutePaths } from "src/constants";
// import { RoutePaths } from "src/constants";

const usePageTitle = (pathname) => {
  
  const [headingTitle, setHeadingTitle] = useState("Timesheet Listing");
  useEffect(() => {
    if (pathname === RoutePaths.TIMESHEET_LISTING) {
      setHeadingTitle("Timesheet Listing");
    } else if (pathname === RoutePaths.APPROVAL_TIMESHEET) {
      setHeadingTitle("Approval Timesheet");
    } else if (pathname === RoutePaths.WEELY_TIMESHEET) {
      setHeadingTitle("Weekly Timesheet");
    } else if (pathname === RoutePaths.CREATE_EDIT_CUSTOMER) {
      setHeadingTitle("Create Customer");
    } else if (pathname === RoutePaths.CREATE_EDIT_EMPLOYEE) {
      setHeadingTitle("Create Employee");
    } else if (pathname === RoutePaths.CUSTOMER_LISTING) {
      setHeadingTitle("Customers");
    } else if (pathname === RoutePaths.EMPLOYEE_LISTING) {
      setHeadingTitle("Employees");
    } else if (pathname === RoutePaths.REPORTS) {
      setHeadingTitle("Reports");
    } else if (pathname === RoutePaths.PROJECTS) {
      setHeadingTitle("Projects");
    } else if (pathname === RoutePaths.CREATE_EDIT_PROJECT) {
      setHeadingTitle(" Create Project");
    } else if (pathname === RoutePaths.EMP_PROJECT_LISTING) {
      setHeadingTitle("Employee Project Assignment");
    } else {
      setHeadingTitle("None");
    }
  }, [pathname]);

  return headingTitle;
};

export default usePageTitle;
