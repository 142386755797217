import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import {
  projectInitialValue,
  validationSchemaModel,
} from "../config/form-config";
import FormikControl from "src/components/formik/FormikControl";
import CTypography from "src/components/CTypography";
import { ContainedButton, OutlinedButton } from "src/components/button-group";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { fetchCountryList, fetchCustomerList } from "src/slices/customer/thunks";
import { selectCountryList, selectCustomerList } from "src/slices/customer/selectors";
import {
  selectManagerList,
  selectPriorityList,
  selectProjectStatusList,
} from "src/slices/project/selectors";
import {
  createProejct,
  editProject,
  fetchManagerList,
  fetchPriorityList,
  fetchProjectStatusList,
} from "src/slices/project/thunk";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "src/constants";
import { IProject } from "src/slices/project/types";

const CreateProject = () => {
  const formikRef = useRef(null);
  const customerList = useSelector(selectCustomerList);
  const managerList = useSelector(selectManagerList);
  const priorityList = useSelector(selectPriorityList);
  const countryList = useSelector(selectCountryList);
  const projectStatusList = useSelector(selectProjectStatusList);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onClickSubmit = (values) => {
    if (state?.project) {
      dispatch(
        editProject({
          ...values,
          projectId: state.project.projectId,
          isActive: state.project.isActive,
        })
      ).then(() => {
        navigate(-1);
      });
      return;
    }
    dispatch(
      createProejct({
        ...values,
        isActive:true
      })
    ).then(()=> navigate(RoutePaths.PROJECTS));
  };

  useEffect(() => {
    if (customerList.length === 0) {
      dispatch(fetchCustomerList());
    }
    if (managerList.length === 0) {
      dispatch(fetchManagerList());
    }
    if (priorityList.length === 0) {
      dispatch(fetchPriorityList());
    }
    if (projectStatusList.length === 0) {
      dispatch(fetchProjectStatusList());
    }
    if (countryList.length === 0) {
      dispatch(fetchCountryList());
    }
  }, [customerList, managerList, priorityList, projectStatusList, countryList, dispatch]);

  useEffect(() => {
    if (state?.project) {
      const project: IProject = state.project;
      formikRef.current.setFieldValue("projectCode", project.projectCode);
      formikRef.current.setFieldValue("name", project.projectName);
      formikRef.current.setFieldValue("priority", project.priorityId);
      formikRef.current.setFieldValue("customerId", project.customerId);
      formikRef.current.setFieldValue("managerId", project.managerId);
      formikRef.current.setFieldValue("projectStatus", project.projectStatusId);
      formikRef.current.setFieldValue("location", project.locationId);
      formikRef.current.setFieldValue("actualHours", project.actualHours);
      formikRef.current.setFieldValue("startDate", new Date(project.startDate));
      formikRef.current.setFieldValue("endDate", new Date(project.endDate));
      formikRef.current.setFieldValue("estimatedHours", project.estimatedHours);
      formikRef.current.setFieldValue("actualBudget", project.actualBudget);
      formikRef.current.setFieldValue("estimatedBudget", project.estimatedBudget);
      formikRef.current.setFieldValue("progress", project.progress);
      formikRef.current.setFieldValue("description", project.description);
    }
  }, [state]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CTypography color={"primary"}>PROJECT INFORMATION</CTypography>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={projectInitialValue}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={(values) => {
            onClickSubmit(values);
          }}
        >
          {({ values, touched, errors, setFieldValue, getFieldProps }) => (
            <Form id="CreateProject">
              <Grid container columnSpacing={6} rowSpacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="InputField"
                    required
                    // disabled={state?.employee}
                    name="projectCode"
                    label={"Project Code"}
                    error={Boolean(touched.projectCode && errors.projectCode)}
                    helperText={touched.projectCode && errors.projectCode}
                    {...getFieldProps("projectCode")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="InputField"
                    required
                    // disabled={state?.employee}
                    name="estimatedBudget"
                    label={"Estimated Budget"}
                    error={Boolean(
                      touched.estimatedBudget && errors.estimatedBudget
                    )}
                    helperText={
                      touched.estimatedBudget && errors.estimatedBudget
                    }
                    {...getFieldProps("estimatedBudget")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="InputField"
                    required
                    // disabled={state?.employee}
                    name="name"
                    label={"Project Name"}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    {...getFieldProps("name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="InputField"
                    required
                    // disabled={state?.employee}
                    name="actualBudget"
                    label={"Actual Budget"}
                    error={Boolean(touched.actualBudget && errors.actualBudget)}
                    helperText={touched.actualBudget && errors.actualBudget}
                    {...getFieldProps("actualBudget")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="customerId"
                    defaultValue=""
                    label="Customer Name"
                    options={customerList.map((cust) => ({
                      label: cust.name,
                      value: cust.id,
                    }))}
                    error={Boolean(touched.customerId && errors.customerId)}
                    helperText={touched.customerId && errors.customerId}
                    {...getFieldProps("customerId")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="InputField"
                    required
                    // disabled={state?.employee}
                    name="estimatedHours"
                    label={"Estimated Hours"}
                    error={Boolean(
                      touched.estimatedHours && errors.estimatedHours
                    )}
                    helperText={touched.estimatedHours && errors.estimatedHours}
                    {...getFieldProps("estimatedHours")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="managerId"
                    label="Manager Name"
                    options={managerList.map((manager) => ({
                      label: manager.firstName,
                      value: manager.employeeId,
                    }))}
                    error={Boolean(touched.managerId && errors.managerId)}
                    helperText={touched.managerId && errors.managerId}
                    {...getFieldProps("managerId")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="InputField"
                    required
                    // disabled={state?.employee}
                    name="actualHours"
                    label={"Actual Hours"}
                    error={Boolean(touched.actualHours && errors.actualHours)}
                    helperText={touched.actualHours && errors.actualHours}
                    {...getFieldProps("actualHours")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    required
                    control="CalendarTime"
                    label="Start Date"
                    dateOrTimeOnly="date"
                    name="startDate"
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    {...getFieldProps("startDate")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="location"
                    label="Location"
                    options={countryList.map((country) => ({
                      label: country.name,
                      value: country.id,
                    }))}
                    error={Boolean(touched.location && errors.location)}
                    helperText={touched.location && errors.location}
                    {...getFieldProps("location")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    required
                    control="CalendarTime"
                    label="End Date"
                    dateOrTimeOnly="date"
                    name="endDate"
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    {...getFieldProps("endDate")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="priority"
                    label="Priority"
                    options={priorityList.map((priority) => ({
                      label: priority.name,
                      value: priority.id,
                    }))}
                    error={Boolean(touched.priority && errors.priority)}
                    helperText={touched.priority && errors.priority}
                    {...getFieldProps("priority")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="InputField"
                    required
                    // disabled={state?.employee}
                    name="description"
                    label={"Description"}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    {...getFieldProps("description")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="SelectField"
                    required
                    name="projectStatus"
                    label="Project Status"
                    options={projectStatusList.map((project) => ({
                      label: project.name,
                      value: project.id,
                    }))}
                    error={Boolean(
                      touched.projectStatus && errors.projectStatus
                    )}
                    helperText={touched.projectStatus && errors.projectStatus}
                    {...getFieldProps("projectStatus")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="InputField"
                    required
                    // disabled={state?.employee}
                    name="progress"
                    label={"Progress"}
                    error={Boolean(touched.progress && errors.progress)}
                    helperText={touched.progress && errors.progress}
                    {...getFieldProps("progress")}
                  />
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item>
                    <ContainedButton
                      // onClick={() => {
                      //   onClickSubmit(values);
                      // }}
                      type="submit"
                    >
                      SUBMIT
                    </ContainedButton>
                  </Grid>
                  <Grid item>
                    <OutlinedButton
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      CANCEL
                    </OutlinedButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default CreateProject;
