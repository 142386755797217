import { IProjectPayload } from "src/slices/project/types";
import * as Yup from "yup";

export const projectInitialValue: IProjectPayload = {
  actualBudget:null,
  actualHours :null,
  customerId:"",
  description:"",
  endDate:"",
  estimatedBudget:null,
  estimatedHours:null,
  location:"",
  managerId:"",
  priority:"",
  progress:null,
  projectCode:"",
  name:"",
  projectStatus:"",
  startDate:"",
}

export const validationSchemaModel = Yup.object().shape({
  // customerId: Yup.number().required(),
  // managerId: Yup.number().required(),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date must be later than start date'),
  actualHours: Yup.number().min(1, 'Hours must be greater than 0').typeError('Hours must be a number'),
  estimatedHours: Yup.number().min(1, 'Hours must be greater than 0').typeError('Hours must be a number'),
  actualBudget: Yup.number().min(1, 'Budget must be greater than 0').typeError('Budget must be a number'),
  estimatedBudget: Yup.number().min(1, 'Budget must be greater than 0').typeError('Budget must be a number'),
  progress: Yup.number().required('Progress is required').typeError('Progress must be a number')
})