import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { AuthState } from "src/types/auth/auth";

const selectSelf = (state: RootState) => state;
const selectLoginInformation = createSelector(
  selectSelf,
  (state) => state.loginInformation
);

export const selectIsUserLoggedIn = createSelector(
  selectLoginInformation,
  (loginInformation: AuthState) => loginInformation.isLoggedIn
);


export const selectUser = createSelector(
  selectLoginInformation,
  (loginInformation: AuthState) => loginInformation.user
);

export const selectUserEmail = createSelector(
  selectLoginInformation,
  (loginInformation: AuthState) => loginInformation.userEmail
);
