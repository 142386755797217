import { IEmpProjectPayload } from "src/slices/employee-project/types";
import * as Yup from "yup";

export const empProjectInitialValue: IEmpProjectPayload = {
  employeeId: null,
   projectId: null,
   startDate: "",
   endDate: "",
   assignmentStatus: true,
   allocation: ""
}

export const validationSchemaModel = Yup.object().shape({
  // customerId: Yup.number().required(),
  // managerId: Yup.number().required(),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date must be later than start date'),
  employeeId: Yup.string().required('Employee is required').typeError('Employee is required'),
  projectId: Yup.string().required('Project is required').typeError('Project is required'),
  assignmentStatus: Yup.boolean().required('Asignment Status is required'),
  allocation: Yup.number().typeError('Allocation must be number').required('Allocation is required'),
})